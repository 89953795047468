@import '/src/styles/variables.scss';

.features {
  padding: 75px 0;
  background-size: cover;
  .container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 50px;
    @media (max-width: $breakpoint-mobile) {
      display: block;
    }
    .feature {
      display: block;
      max-width: 33%;
      @media (max-width: $breakpoint-mobile) {
        max-width: 100%;
        margin: 0 auto 50px;
      }
      .feature-link {
        display: block;
        position: relative;
        height: 322px;
        width: 322px;
        max-width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-bottom: 20px;
        @media (max-width: $breakpoint-mobile) {
          min-width: 100%;
        }
        &:hover {
          .project-information {
            background-color: rgba(0, 0, 0, 0);
          }
          .arrow {
            bottom: 75%;
            right: 7%;
            -webkit-transform: translateY(-100%);
            -ms-transform: translateY(-100%);
            transform: translateY(-100%);
            img {
              transform: scale(1.9);
              transition: -webkit-transform .9s ease-in-out;
              transition: transform .9s ease-in-out;
            }
          }
          .overlay {
            opacity: 1;
          }
        }
        .feature-information {
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: #0000008f;
          height: 85px;
          width: 100%;
          position: absolute;
          bottom: 0;
          padding: 10px 20px;
          z-index: 1;
          p {
            font-family: $brandheader;
            font-size: 25px;
            font-weight: bold;
            color: #fff;
            text-rendering: optimizeLegibility;
            letter-spacing: .5px;
            max-width: 80%;
            @media (max-width: $breakpoint-tablet) {
              font-size: 20px;
            }
          }
        }
        .arrow {
          bottom: 15%;
          right: 20px;
          transition: all 0.5s ease;
          will-change: bottom;
          position: absolute;
          z-index: 2;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(16, 166, 58, 0.8);
          opacity: 0;
          transition: 0.3s ease-out;
        }
      }
      .description {
        font-size: 17px;
        line-height: 25px;
        max-width: 322px;
        .social-links {
          margin-top: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 20px;
          a {
            display: block;
            width: 40px;
            height: 40px;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}
