@import '/src/styles/variables.scss';

.vertical-quotes {
  margin: 100px 0;
  @media (max-width: $breakpoint-mobile) {
    display: block;
  }
  .quotes-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
    @media (max-width: $breakpoint-mobile) {
      display: block;
    }
    .quote {
      width: 30%;
      @media (max-width: $breakpoint-mobile) {
        width: 100%;
        margin-bottom: 50px;
      }
      .headshot {
        width: 100%;
        max-width: 300px;
        height: 300px;
        position: relative;
        margin-bottom: 30px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      h3 {
        color: $brandgreen;
        font-size: 18px;
        margin-bottom: 20px;
        @media (max-width: $breakpoint-mobile) {
          text-align: left;
        }
      }
      p {
        @media (max-width: $breakpoint-mobile) {
          text-align: left;
        }
      }
    }
  }
}
