@import '/src/styles/variables.scss';

.about-3rc {
  position: relative;
  text-align: center;
  margin-bottom: 100px;
  .container {
    padding: 50px;
    border: 2px solid #000;
    border-radius: 25px;
    @media (max-width: $breakpoint-mobile) {
      padding: 0 20px;
      border: none;
      br {
        display: block;
      }
    }
  }
  h3 {
    position: relative;
    margin-bottom: 35px;
    font-size: 24px;
    &:after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      width: 150px;
      background: #000;
    }
  }
   p {
     font-size: 17px;
   }
}
