@import '/src/styles/variables.scss';

.portfolio-copy-block {
  position: relative;
  padding: 50px 0;
  background: #fff;
  p {
    display: block;
    width: 650px;
    max-width: 100%;
    margin: 0 auto;
    &.headline {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .body {
    p {
      font-size: 17px;
    }
  }
}
