@import '/src/styles/variables.scss';

.copy-block {
  position: relative;
  padding: 50px 0;
  .container {
    .content {
      max-width: 870px;
      margin: 0 auto;
      h3 {
        font-size: 35px;
      }
      p {
        font-size: 17px;
      }
    }
  }
}
