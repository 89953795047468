@import './reset.scss';
@import './variables.scss';
@import './btn.scss';
@import './fonts.scss';

html,
body {
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  width:100%;
  overflow-x:hidden;
  @media (max-width: $breakpoint-mobile) {
    br:not(.always) {
      display: none;
    }
    .wysiwyg {
      br {
        display: block;
      }
    }
  }
}
body {
  max-width: 1800px;
  margin: 0 auto;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 800;
}
p {
  font-size: 20px;
  line-height: 29px;
}

h1 {
  font-size: 60px;
  line-height: normal;
  @media (max-width: $breakpoint-mobile) {
    font-size: 40px;
  }
}
h2 {
  font-size: 60px;
  @media (max-width: $breakpoint-mobile) {
    font-size: 40px;
  }
}

.container {
  max-width: $container;
  margin: 0 auto;
  padding: 0 20px;
}
