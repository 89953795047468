@import '/src/styles/variables.scss';

.portfolio-desktop-comp {
  position: relative;
  margin: 200px 0 50px;
  padding: 100px 0;
  @media (max-width: $breakpoint-tablet) {
    margin: 0 0 300px;
    padding: 50px 0;
  }
  .container {
    display: flex;
    justify-content: center;
    align-content: center;
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      align-items: center;
    }
    .copy {
      width: 40%;
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
      }
      .headline {
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 30px;
      }
      .body {
        font-size: 17px;
      }
    }
    .comp {
      width: 60%;
      position: relative;
      @media (max-width: $breakpoint-tablet) {
        margin-bottom: -200px;
      }
      @media (max-width: $breakpoint-mobile) {
        width: 100%;
      }
      img {
        position: absolute;
        bottom:-50px;
        max-width: 850px;
        @media (max-width: $breakpoint-tablet) {
          position: relative;
          width: 100%;
        }
      }
    }
  }
  &.white-copy {
    .container {
      p {
        color: #fff;
      }
    }
  }
}
