@import '/src/styles/variables.scss';


@keyframes float {
  0% { transform: translate(0, 0); }
  50% { transform: translate(20px, 15px); }
  100% { transform: translate(0, 0); }
  animation-timing-function: ease-in-out;
}


.intro-2024 {
  position: relative;
  height: 100vh;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  @media (max-width: $breakpoint-tablet) {
    min-height: auto;
  }
  @media (max-width: $breakpoint-tablet) {
    height: 60vh;
    min-height: auto;
  }
  @media (max-width: $breakpoint-mobile) {
    height: 30vh;
    min-height: auto;
  }
  .gift-tag {
    position: absolute;
    top: -20px;
    animation: float 4s ease-in-out infinite;
    animation-direction: alternate-reverse;
    width: auto;
    // max-width: 100%;
    max-height: 115%;
  }
  .background-media {
    .circle-burst {
      &.yellow {
        img {
          &.circle {
            animation: rotate-counter-clockwise 450s linear infinite, move-clockwise 25s linear infinite;
          }
          &.burst {
            animation: rotate-clockwise 50s linear infinite, move-counter-clockwise 20s linear infinite;
          }
        }
        &.one { top: -8%; left: -2%; }
        &.two { top: 78%; left: 14%; }
        &.three { top: 35%; left: 47%; }
        &.four { top: 70%; left: 90%; }
      }
      &.red {
        img {
          &.circle {
            animation: rotate-clockwise 60s linear infinite, move-counter-clockwise 20s linear infinite;
          }
          &.burst {
            animation: rotate-counter-clockwise 40s linear infinite, move-clockwise 30s linear infinite;
          }
        }
        &.one { top: 40%; left: 6%; }
        &.two { top: -12%; left: 45%; }
        &.three { top: 75%; left: 60%; }
        &.four { top: 30%; left: 85%; }
      }
      &.blue {
        img {
          &.circle {
            animation: rotate-counter-clockwise 60s linear infinite, move-counter-clockwise 30s linear infinite;
          }
          &.burst {
            animation: rotate-clockwise 80s linear infinite, move-counter-clockwise 20s linear infinite;
          }
        }
        &.one { top: 13%; left: 20%; }
        &.two { top: 90%; left: 37%; }
        &.three { top: 45%; left: 70%; }
        &.four { top: -1%; left: 95%; }
      }
      &.green {
        img {
          &.circle {
            animation: rotate-clockwise 50s linear infinite, move-clockwise 40s linear infinite;
          }
          &.burst {
            animation: rotate-counter-clockwise 80s linear infinite, move-counter-clockwise 20s linear infinite;
          }
        }
        &.one { top: 98%; left: -5%; }
        &.two { top: 55%; left: 30%; }
        &.three { top: 5%; left: 70%; }
        &.four { top: 99%; left: 80%; }
      }
    }
  }
}
