@import '/src/styles/variables.scss';

@keyframes starFade {
  0%   {
    opacity: 0.2;
    transform: rotate(-9deg) scale(1);
  }
  50% {
    opacity: 1;
    transform: rotate(10deg) scale(1.1);
  }
  100% {
    opacity: 0.2;
    transform: scale(1);
    transform: rotate(-10deg) scale(1);
  }
}

@keyframes giftLid {
  0% {
    transform: rotate(10deg) translate(2px, 10px);
  }
  2% {
    transform: rotate(10deg) translate(-2px, 10px);
  }
  6% {
    transform: rotate(10deg) translate(2px, 10px);
  }
  10% {
    transform:rotate(10deg)  translate(-2px, 10px);
  }
  13% {
    transform: rotate(10deg) translate(2px, 10px);
  }
  15% {
    transform: rotate(10deg) translate(-2px, 10px);
  }
  18% {
    transform: rotate(10deg) translate(2px, 10px);
  }
  21% {
    transform: rotate(10deg) translate(-2px, 10px);
  }
  24% {
    transform: rotate(10deg) translate(2px, 10px);
  }
  27% {
    transform: rotate(10deg) translate(-2px, 10px);
  }
  30% {
    transform: rotate(10deg) translate(2px, 10px);
  }
  33% {
    transform: rotate(10deg) translate(-2px, 10px);
  }
  36% {
    transform: rotate(10deg) translate(2px, 10px);
  }
  39% {
    transform: rotate(10deg) translate(-2px, 10px);
  }
  41% {
    transform: rotate(10deg) translate(2px, 10px);
  }
  44% {
    transform: rotate(10deg) translate(-2px, 10px);
  }
  47% {
    transform: rotate(10deg) translate(2px, 10px);
  }
  49% {
    transform: rotate(10deg) translate(2px, 10px);
  }
  50%   {
    transform: rotate(10deg) scale(1)  translateY(10px);
  }
  75% {
    transform: rotate(-10deg) scale(1.05) translateY(-10px);
  }
  100% {
    transform: rotate(10deg) scale(1) translateY(10px);
  }
}

@keyframes giftBox {
  0% {
    transform: translate(2px, 5px);
  }
  2% {
    transform: translate(-2px, 5px);
  }
  6% {
    transform: translate(2px, 5px);
  }
  10% {
    transform: translate(-2px, 5px);
  }
  13% {
    transform: translate(2px, 5px);
  }
  15% {
    transform: translate(-2px, 5px);
  }
  18% {
    transform: translate(2px, 5px);
  }
  21% {
    transform: translate(-2px, 5px);
  }
  24% {
    transform: translate(2px, 5px);
  }
  27% {
    transform: translate(-2px, 5px);
  }
  30% {
    transform: translate(2px, 5px);
  }
  33% {
    transform: translate(-2px, 5px);
  }
  36% {
    transform: translate(2px, 5px);
  }
  39% {
    transform: translate(-2px, 5px);
  }
  41% {
    transform: translate(2px, 5px);
  }
  44% {
    transform: translate(-2px, 5px);
  }
  47% {
    transform: translate(2px, 5px);
  }
  49% {
    transform: translate(-2px, 5px);
  }
  50%   {
    transform: rotate(0) translateY(5px);
  }
  75% {
    transform: rotate(3deg) translateY(-5px);
  }
  100% {
    transform: rotate(0) translateY(5px);
  }
}


.hero {
  position: relative;
  min-height: 800px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position:  center bottom;
  padding: 50px 0;
  .star {
    animation-name: starFade;
    animation-iteration-count: infinite;
    animation-direction: foward;
    position: absolute;
    &.star-1 {
      animation-duration: 4s;
      top: 50px;
      right: 100px;
    }
    &.star-2 {
      animation-duration: 3s;
      top: 100px;
      left: 50px;
    }
    &.star-3 {
      animation-duration: 5s;
      bottom: 310px;
      left: 200px;
    }
  }
  .logo {
    position: relative;
    z-index: 1;
    text-align: center;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      visibility: hidden;
    }
    img {
      max-width: 100%;
    }
    p {
      width: 800px;
      max-width: 100%;
      margin-top: 50px;
      text-align: center;
      color: #fff;
      font-size: 22px;
      font-weight: 600;
    }
  }
  .gift-animation {
    position: relative;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    .gift {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      &.lid {
        animation-name: giftLid;
        animation-iteration-count: infinite;
        animation-duration: 8s;
        animation-direction: forward;
        z-index: 2;
        left: calc(50% - 89px)
      }
      &.box {
        animation-name: giftBox;
        animation-iteration-count: infinite;
        animation-duration: 8s;
        animation-direction: foward;
        left: calc(50% - 89px);
        z-index: 1;
      }
    }
  }



  @media (max-width: $breakpoint-mobile) {
    min-height: 700px;
    .content {
      img {
        width: 100%;
        max-width: 460px;
      }
    }
  }

}
