@import '/src/styles/variables.scss';

@keyframes wipe-in-right {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes wipe-in-left {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.portfolio-before-and-after {
  position: relative;
  padding: 50px 0;
  transition: 1s ease;
  &.extra-space {
    margin: 50px 0;
  }
  &.copy-included {
    .container {
      @media (max-width: $breakpoint-tablet) {
        height: 700px;
      }
    }
  }
  .container {
    position: relative;
    height: 590px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
        justify-content: center;
        height: 460px;
    }
    .body-copy {
      width: 25%;
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        height: auto;
      }
      p {
        font-size: 17px;
        transition: 1.25s ease-in-out;
        @media (max-width: $breakpoint-tablet) {
          text-align: center;
        }
      }
    }
    .comp {
      position: relative;
      width: 50%;
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        height: 365px;
      }
      .image {
        position: absolute;
        bottom: -50px;
        left: 0;
        width: 100%;
        transition: clip-path 0.2s ease-in-out;
        @media (max-width: $breakpoint-tablet) {
          width: auto;
          max-width: 100%;
          max-height: 365px;
          left: 50%;
          top: 0;
          bottom: auto;
          transform: translateX(-50%);
        }
      }
    }
    .toggle-container {
      width: 15%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        display: block;
        height: 35px;
      }
      button {
        border-radius: 20px;
        width: 150px;
        max-width: 100%;
        padding: 10px 20px;
        text-transform: uppercase;
        font-weight: 600;
        transition: 1.25s ease-in-out;
        @media (max-width: $breakpoint-tablet) {
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
  &.before {
    background-color: #fff;
    .container {
      .body-copy {
        p {
          color: #000;
        }
      }
      .comp {
        .image{
          &.before {
            animation: 1.0s cubic-bezier(.25, 1, .30, 1) wipe-in-right both;
            z-index: 2;
          }
          &.after {
            z-index: 1;
          }
        }
      }
      .toggle-container {
        button {
          background: $brandgreen;
          color: #fff;
        }
      }
    }
  }
  &.after {
    background-color: $branddarkbg;
    .container {
      .body-copy {
        p {
          color: #fff;
        }
      }
      .comp {
        .image{
          &.before {
            z-index: 1;
          }
          &.after {
            animation: 1.25s cubic-bezier(.25, 1, .30, 1) wipe-in-left both;
            z-index: 2;
          }
        }
      }
      .toggle-container {
        button {
          background: #fff;
          color: $brandgreen;
        }
      }
    }
  }
}
