@import '/src/styles/variables.scss';

.copy-and-carousel-block {
  position: relative;
  .background-media {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 0;
    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
    > div {
      width: 50%;
      @media (max-width: $breakpoint-mobile) {
        width: 100%;
      }
      &.copy-background {
        background-color: #fff;
      }
      &.carousel-background {
        background-color: $branddarkbg;
      }
    }
  }
  .container {
    position: relative;
    padding: 50px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $breakpoint-mobile) {
      padding: 0;
      display: block;
    }
    .copy, .carousel-root {
      width: 50%;
      @media (max-width: $breakpoint-mobile) {
        width: 100%;
      }
    }
    .copy {
      @media (max-width: $breakpoint-mobile) {
        background: #fff;
        padding: 50px 20px;
      }
      h2, p, table td {
        color: $brandgreen;
      }
      table {
        width: 80%;
        @media (max-width: $breakpoint-mobile) {
          width: 100%;
        }
        tr {
          td {
            font-size: 30px;
            padding: 10px 0;
            @media (max-width: $breakpoint-mobile) {
              font-size: 20px;
            }
            &:nth-of-type(1) {
              width: 75%;
            }
            &:nth-of-type(2) {
              width: 25%;
              font-weight: 600;
            }
          }
        }
      }
    }
    .carousel-root {
      padding: 0 50px;
      @media (max-width: $breakpoint-mobile) {
        padding: 50px 20px;
        background: $branddarkbg;
      }
      .carousel {
        overflow: unset;
        .slider-wrapper {
          .slider {
            .slide {
              .item {
                .star-rating {
                  margin-bottom: 30px;
                  img {
                    width: 200px;
                  }
                }
                p {
                  color: #fff;
                }
              }
            }
          }
        }
        .control-dots {
          bottom: -50px;
        }
      }
    }
  }
}
