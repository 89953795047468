@import '/src/styles/variables.scss';

.portfolio-hero {
  min-height: 60vh;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: center;
  background-size: cover;
  padding-top: 60px;
  .content {
    text-align: center;
    border-top: 1px solid #fff;
    margin: 10%;
    h1, h2 {
      color: #fff;
      font-family: $brandheader;
    }
    h1 {
      font-weight: bold;
      font-size: 75px;
      margin: 40px 0;
      @media (max-width: $breakpoint-mobile) {
        font-size: 40px;
      }
    }
    h2 {
      font-size: 18px;
    }
  }
}
