@import '/src/styles/variables.scss';


.donations-mobile {
  &.select-donation {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    .description {
      width: 100%;
      padding: 0 20px;
      margin-bottom: 50px;
      text-align: center;
      h3 {
        font-size: 40px;
        margin-bottom: 20px;
      }
      p {
        color: #033837;
        margin-bottom: 20px;
        text-align: left;
      }
      ol {
        text-align: left;
      }
      button {
        margin-top: 30px;
        border-radius: 25px;
        background: #DE5024;
        color: #fff;
        font-size: 17px;
        font-weight: 600;
        padding: 15px 30px;
        box-shadow:  2px 2px 10px -2px #707070;
      }
    }
    .donation-items {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      .item {
        width: 50%;
        margin-bottom: 40px;
        text-align: center;
        transition: 0.2s ease-in-out;
        .item-visual {
          width: 150px;
          height: 150px;
          margin: 0 auto 10px;
          border-radius: 50%;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            height: 200px;
            width: 200px;
            opacity: 0;
            z-index: -1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: 0.2s;
            background: inherit;
            border-radius: 50%;
          }
          .main {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.3s ease-out;
            transform-origin: center;
          }
          .main {
            width: 100%;
            max-width: 85%;
            max-height: 65%;
          }
        }
        p {
          font-family: $manofa;
          font-weight: 600;
          color: #033837;
          opacity: 1;
          transition: 0.2s;
        }
        &.not-selected {
          opacity: 0.5;
        }
        &.selected {
          .item-visual {
            &:after {
              opacity: 0.3;
            }
          }
          p {
            opacity: 0;
          }
        }
      }
    }

    .mobile-selected {
      .bg {
        background-color: rgba(0, 0, 0, 0.6);
        width: 100vw;
        height: 100vh;
        position: fixed;
        left: 0;
        bottom: 0;
        opacity: 0;
        z-index: -1;
        transition: 0.2s;
      }
      .panel {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 85vh;
        position: fixed;
        left: 0;
        bottom: -85vh;
        opacity: 1;
        z-index: 9;
        transition: 0.4s ease;
        padding: 50px 20px 20px;
        background-color: #F5F4E7;
        .close {
          position: absolute;
          right: 20px;
          top: 20px;
          z-index: 9;
        }
        .carousel-root {
          width: 100%;
          .carousel {
            text-align: center;
            padding-bottom: 20px;
            .slider-wrapper {
              .slide {
                .description {
                  .item-visual {
                    width: 100px;
                    height: 100px;
                    margin: 0 auto 10px;
                    border-radius: 50%;
                    position: relative;
                    .main {
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      transition: all 0.3s ease-out;
                      transform-origin: center;
                    }
                    .main {
                      width: 100%;
                      max-width: 85%;
                      max-height: 65%;
                    }
                  }
                  h3 {
                    font-size: 30px;
                  }
                  button {
                    margin-top: 20px;
                  }
                }
              }
            }
            .control-arrow {
              top: auto;
              bottom: 20px;
              background: none;
              &.control-prev {
                left: 55px;
                &:before {
                  border-right: 8px solid #000;
                }
              }
              &.control-next {
                right: 55px;
                &:before {
                  border-left: 8px solid #000;
                }
              }
            }
            &:after {
              content: "SWIPE TO BROWSE";
              position: absolute;
              bottom: 30px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
      &.active {
        z-index: 7;
        .bg {
          opacity: 1;
          z-index: 8;
        }
        .panel {
          bottom: -2px;
        }
      }
    }

  }


  @media (max-width: $breakpoint-tablet) {
    .select-donation {
      display: block;
      .description, .donation-items {
        width: 100%;
      }
      .description {
        margin-bottom: 30px;
      }
      .donation-items {
        max-width: 600px;
        margin: 0 auto;
      }
    }
  }

  @media (max-width: $breakpoint-mobile) {
    .select-donation {
      .donation-items {
        max-width: 450px;
        .item {
          width: 50%;
          margin-bottom: 40px;
        }
      }
    }
  }
}
