@import '/src/styles/variables.scss';

.job-description {
  position: relative;
  text-align: left;
   p {
     font-size: 17px;
     strong {
       font-weight: 600;
     }
   }
   ul {
     list-style: disc;
     list-style-position: inside;
   }
}
