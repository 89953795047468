@import '/src/styles/variables.scss';

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-3px);
  }
}

.holiday-overview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 100vh;
  width: 100vw;
  background: #248065;
  padding: 0 0 40px;
  overflow: hidden;
  z-index: 99;
  // &.active {
  //   position: fixed;
  //   top: 0;
  //   z-index: 99;
  // }
  h2 {
    position: relative;
    font-family: Tuppence;
    color: #F1EBDD;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    max-width: 750px;
    margin: 0 auto 20px;
    @media (max-width: $breakpoint-tablet) {
      font-size: 30px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 41px;
      background: #DEFFF9;
      filter: blur(50px);
      opacity: 0.2;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
  .instructions {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
    display: flex;
    margin: 0 auto 50px;
    transition: 0.2s ease-in;
    width: auto;
    padding: 10px 20px;
    border-radius: 10px;
    // background: rgba(239, 234, 220, 0.87);
    background: #D8543C;
    box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.15);
    transition: 0.2s ease-in-out;
    &:hover {
        transform: translateY(-2px);
    }
    p {
      color: #fff;
      font-size: 20px;
      line-height: 24px;
      font-family: 'space-grotesk-variable', sans-serif;
      text-align: center;
      font-weight: bold;
      margin-top: 3px;
    }
  }
  .container {
    width: 100%;
    .game-board {
      position: relative;
      width: 100%;
      max-width: 875px;
      height: 500px;
      margin: 0 auto;
      z-index: 1;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-position: center;
        background-image: url('./../assets/game-path.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
      }
      .circle {
        overflow: hidden;
        width: 5000px;
        height: 5000px;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        z-index: 9;
        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
      .circle-dec {
        // position: relative;
        overflow: hidden;
        width: 120px;
        height: 120px;
        background: transparent;
        border-radius: 50%;
        position: absolute;
        z-index: 2;
        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
          // position: absolute;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%, -50%);
          // &.foreground {
          //   z-index: 2;
          // }
          // &.background {
          //   z-index: 1;
          // }
        }
        &.two {
          top: 290px;
          right: 65%;
        }
        &.three {
          top: 85px;
          right: 40%;
        }
        &.four {
          top: 170px;
          right: 20%;
        }
        &.five {
          top: 290px;
          right: 35%;
        }
        &.six {
          top: 310px;
          right: 6%;
        }
      }
    }
  }
}
