@import '/src/styles/variables.scss';

.full-width-image {
  position: relative;
  img {
    width: 100%;
    &.desktop {
      display: block;
      @media (max-width: $breakpoint-mobile) {
        display: none;
      }
    }
    &.mobile {
      display: none;
      @media (max-width: $breakpoint-mobile) {
        display: block;
      }
    }
  }
}
