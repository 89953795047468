@import '/src/styles/variables.scss';

.charities-mobile {
  position: relative;
  .slide {
    .content {
      padding: 50px 20px 100px;
      background: #12A185;
      .index {
        width: 60px;
        height: 60px;
        background: #248065;
        border-radius: 50%;
        margin: 0 auto;
        padding: 10px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          color: #FFE6CE;
          font-family: "space-grotesk-variable";
          font-size: 38px;
          font-weight: bold;
        }
      }
      .illustration {
        width: 100%;
        height: 200px;
        max-width: 300px;
        position: relative;
        margin: 0 auto;
        padding: 0 20px;
        img {
          position: absolute;
          width: 100%;
          animation-iteration-count: infinite;
          &.foreground {
            left: -30px;
            z-index: 2;
            animation-duration: 5s;
            animation-name: bounceFloatForeground;
          }
          &.background {
            top: 0;
            left: 0;
            z-index: 1;
            animation-duration: 10s;
            animation-name: bounceFloatBackground;
          }
        }
      }
      h3 {
        text-align: center;
        font-family: Tuppence;
        font-size: 40px;
        color:  #FFE6CE;
      }
    }
    .employee-testimonials {
      padding: 50px 0 50px;
      position: relative;
      background: #96E1C6;
      &:before {
        content: '';
        position: absolute;
        top: -75px;
        left: -40px;
        background-size: cover;
        background-repeat: no-repeat;
        width: 110%;
        height: 100px;
        background-image: url('./../assets/employee-dec-with-border.png');
        @media (max-width: $breakpoint-mobile) {
          top: -50px;
        }
      }
      .carousel {
        overflow: visible;
        padding-bottom: 20px;
        .control-dots {
          bottom: -20px;
          .dot {
            background: #FE8874;
            width: 21px;
            height: 21px;
            opacity: 0.7;
            transition: 0.25s ease-in;
            &.selected {
              opacity: 1;
            }
          }
        }
        .control-arrow {
          &.control-prev, &.control-next {
            top: auto;
            bottom: -35px;
            &:before {
              border: none;
              background-size: 50px 50px;
              height: 50px;
              width: 50px;
            }
            &:hover {
              background: none;
            }
          }
          &.control-prev {
            left: calc(50% - 150px);
            &:before {
              background-image: url('./../assets/carousel-prev.svg');
            }
          }
          &.control-next {
            right: calc(50% - 150px);
            &:before {
              background-image: url('./../assets/carousel-next.svg');
            }
          }
        }
       }
      .employee {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: relative;
        margin: 0 auto 30px;
        width: 300px;
        max-width: 100%;
        text-align: left;
        p {
          color: #FFE6CE;
        }
        .title {
          p {
            font-size: 12px;
            font-weight: bold;
            color: #0B333E;
            text-transform: uppercase;
            font-family: "space-grotesk-variable";
          }
        }
        .name {
          margin-bottom: 20px;
          p {
            font-size: 32px;
            font-family: Tuppence;
            color: #0B333E;
          }
        }
        .quote {
          text-wrap: wrap;
          p {
            position: relative;
            font-size: 15px;
            height: 100%;
            font-family: "space-grotesk-variable";
            color: #0B333E;
            img {
              width: auto;
              position: relative;
              top: 0;
            }
          }
        }
      }
    }


    // &.slide-1 {
    //   .content {
    //     background: #1289AB;
    //   }
    //   .employee-testimonials {
    //     background: #0F7997;
    //     &:before {
    //       background-image: url('./../assets/employee-dec-blue.svg');
    //     }
    //   }
    // }
    // &.slide-2 {
    //   .content {
    //     background: #25837E;
    //   }
    //   .employee-testimonials {
    //     background: #1C6E69;
    //     &:before {
    //       background-image: url('./../assets/employee-dec-green.svg');
    //     }
    //   }
    // }
    // &.slide-3 {
    //   .content {
    //     background: #EE8786;
    //   }
    //   .employee-testimonials {
    //     background: #D35E5D;
    //     &:before {
    //       background-image: url('./../assets/employee-dec-red.svg');
    //     }
    //   }
    // }
    // &.slide-4 {
    //   .content {
    //     background: #1289AB;
    //   }
    //   .employee-testimonials {
    //     background: #0F7997;;
    //     &:before {
    //       background-image: url('./../assets/employee-dec-blue.svg');
    //     }
    //   }
    // }
    // &.slide-5 {
    //   .content {
    //     background: #25837E;
    //   }
    //   .employee-testimonials {
    //     background: #1C6E69;
    //     &:before {
    //       background-image: url('./../assets/employee-dec-green.svg');
    //     }
    //   }
    // }
    // &.slide-6 {
    //   .content {
    //     background: #D96C6B;
    //   }
    //   .employee-testimonials {
    //     background: #D35E5D;
    //     &:before {
    //       background-image: url('./../assets/employee-dec-red.svg');
    //     }
    //   }
    // }

    &.slide-7 {
      padding: 50px 0;
      background: #248065;
      h2 {
        font-family: Tuppence;
        font-size: 28px;
        text-align: center;
        color: #F1EBDD;
      }
      .options {
        margin-top: 30px;
        .option {
          position: relative;
          background: #fff;
          padding: 20px 90px;
          border-radius: 10px;
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.20);
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          align-items: center;
          .illustration {
            position: absolute;
            top: 0;
            left: 0;
            width: 70px;
            height: 100%;
            border-radius: 10px 0 0 10px;
            background: #12A185;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          h4 {
            font-family: Tuppence;
            font-weight: bold;
          }
          .selection-indicator {
            position: absolute;
            width: 30px;
            height: 30px;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            background: #E9E9E9;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              height: 25px;
              width: 25px;
              display: block;
              background: #FD8874;
              border-radius: 50%;
              opacity: 0;
              transition: 0.2s ease-in;
            }
            &.selected {
              &:before {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.modal {
  &.mobile {
    padding-top: 75px;
    .modal-content {
      .selection {
        .illustration {
          position: relative;
          &:before {
            top: 0;
            height: 50px;
            width:50px;
          }
          &:after {
            right: -55px;
            height: 50px;
            width: 50px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .selection-name {
          padding: 5px 10px 10px;
        }
      }
      h3 {
        font-size: 24px;
      }
      p {
        font-size: 14px;
      }
      form {
        label {
          font-size: 20px;
          input {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
