@import '/src/styles/variables.scss';

.mission {
  position: relative;
  padding: 0 50px;
  text-align: center;
  p {
    width: 800px;
    max-width: 100%;
    margin: 0 auto 30px;
    font-family: $manofa;
    font-size: 24px;
    color: #033837;
  }
  .learn-more {
    width: 100%;
    margin-top: 30px;
    cursor: pointer;
    p {
      text-transform: uppercase;
      margin-bottom: 0;
      font-weight: 600;
      color: #005B4A;
    }
    img {
      margin-top: 20px;
      transition: 0.2s ease-in-out;
    }
    &:hover {
      img {
        transform: translateY(-2px);
      }
    }
  }

  @media (max-width: $breakpoint-mobile) {
    p {
      font-size: 18px;
    }
  }
}
