@import '/src/styles/variables.scss';

.company-hero {
  position: relative;
  padding: 150px 0 85px;
  background-size: cover;
  background-position: center;
  color: #fff;
  h1 {
    position: relative;
    font-size: 90px;
    margin-bottom: 20px;
    max-width: 90%;
    @media (max-width: $breakpoint-mobile) {
      font-size: 40px;
      max-width: 100%;
    }
    &::before {
      content: '';
      position: absolute;
      top: -10px;
      background-color: white;
      height: 8px;
      width: 400px;
      max-width: 100%;
      @media (max-width: $breakpoint-mobile) {
        height: 4px;
        max-width: 50%;
      }
    }
  }
   p {
     font-family: $brandbody;
     font-size: 24px;
     @media (max-width: $breakpoint-mobile) {
        font-size: 18px;
      }
   }
}
