@import '/src/styles/variables.scss';

@keyframes descriptionFade {
  0%   {
    opacity: 0;
    transform: translateY(-4px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.donations-desktop {
  &.select-donation {
    display: flex;
    align-content: center;
    align-items: center;
    .description {
      position: relative;
      width: 40%;
      padding: 0 20px;
      animation-name: descriptionFade;
      animation-duration: 0.4s;
      h3 {
        font-size: 40px;
        margin-bottom: 20px;
      }
      p {
        color: #033837;
        margin-bottom: 20px;
      }
      .action-options {
        margin-top: 30px;
        display: flex;
        align-items: center;
        button {
          &.select {
            margin-right: 50px;
            border-radius: 25px;
            background: #DE5024;
            color: #fff;
            font-size: 17px;
            font-weight: 600;
            padding: 15px 30px;
            box-shadow:  2px 2px 10px -2px #707070;
          }
        }
      }
    }
    .donation-items {
      width: 60%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      .item {
        width: 30%;
        margin: 0  10px 40px;
        text-align: center;
        transition: 0.2s ease-in-out;
        .item-visual {
          width: 150px;
          height: 150px;
          margin: 0 auto 10px;
          border-radius: 50%;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            height: 200px;
            width: 200px;
            opacity: 0;
            z-index: -1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: 0.2s;
            background: inherit;
            border-radius: 50%;
          }

          .main, .sub {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.3s ease-out;
            transform-origin: center;
          }
          .main {
            width: 100%;
            max-width: 85%;
            max-height: 65%;
            z-index: 2;
            transition: all 0.2s ease-out;
          }
          .sub {
            opacity: 0;
            z-index: 1;
            transform: rotate(45deg) translate(-50%, -50%);
            width: 50%;
          }
          &:hover {
            .main {
              transform: translate(-50%, -50%) rotate(10deg) scale(1.1);
            }
            .sub {
              opacity: 1;
              transform: rotate(-45deg);
              &.one {
                transform: rotate(-45deg) translate(-65px, -65px);
              }
              &.two {
                transform: rotate(-45deg) translate(25px, -65px);
              }
              &.three {
                transform: rotate(-45deg) translate(-65px, 25px);
              }
              &.four {
                transform: rotate(-45deg) translate(25px, 25px);
              }
            }
          }

        }
        p {
          font-family: $manofa;
          font-weight: 600;
          color: #033837;
          opacity: 1;
          transition: 0.2s;
        }
        &.not-selected {
          opacity: 0.5;
        }
        &.selected {
          .item-visual {
            &:after {
              opacity: 0.3;
            }
          }
          p {
            opacity: 0;
          }
        }

        &.winter-clothes {
          .item-visual {
            &:hover {
              .sub {
                &.one {
                  transform: translate(-85px, -95px);
                }
                &.two {
                  transform: translate(25px, -85px);
                }
                &.three {
                  transform: translate(-85px, 25px);
                }
                &.four {
                  transform: translate(30px, 20px);
                }
              }
            }
          }
        }

        &.kitchen-supplies {
          .item-visual {
            &:hover {
              .sub {
                &.one {
                  transform: translate(-50px, -95px);
                }
                &.two {
                  transform: translate(30px, -95px);
                }
                &.three {
                  transform: translate(-105px, -45px);
                }
                &.four {
                  transform: translate(20px, 0);
                }
              }
            }
          }
        }

        &.personal-care {
          .item-visual {
            &:hover {
              .sub {
                &.one {
                  transform: translate(-115px, -115px);
                }
                &.two {
                  transform: translate(45px, -115px);
                }
                &.three {
                  transform: translate(-105px, 0);
                }
                &.four {
                  transform: translate(60px, 30px);
                }
              }
            }
          }
        }

        &.childrens-essentials {
          .item-visual {
            &:hover {
              .sub {
                &.one {
                  transform: translate(-110px, -95px);
                }
                &.two {
                  transform: translate(-30px, -130px);
                }
                &.three {
                  transform: translate(-85px, 15px);
                }
                &.four {
                  transform: translate(40px, -70px);
                }
              }
            }
          }
        }

        &.home-linens {
          .item-visual {
            &:hover {
              .sub {
                &.one {
                  transform: translate(-125px, -105px);
                }
                &.two {
                  transform: translate(5px, -85px);
                }
                &.three {
                  transform: translate(50px, 0);
                }
              }
            }
          }
        }

      }
    }
  }


  @media (max-width: $breakpoint-tablet) {
    .select-donation {
      display: block;
      .description, .donation-items {
        width: 100%;
      }
      .description {
        margin-bottom: 30px;
      }
      .donation-items {
        max-width: 600px;
        margin: 0 auto;
      }
    }
  }

  @media (max-width: $breakpoint-mobile) {
    .select-donation {
      .donation-items {
        max-width: 450px;
        .item {
          width: 50%;
          margin-bottom: 40px;
        }
      }
    }
  }
}
