@import '/src/styles/variables.scss';

@keyframes bounceFloatForeground {
  0%, 100% {
    transform: translate(30px, 0) translateY(0);
  }
  50% {
    transform: translate(35px, 10px) translateY(-15px);
  }
}

@keyframes bounceFloatBackground {
  0%, 100% {
    transform: translate(-25px, 0) translateY(0);
  }
  50% {
    transform: translate(-20px, 0) translateY(5px);
  }
}

.holiday-gift {
  .primary-header, .primary-footer {
    display: none;
  }
}

/* src/components/HorizontalScrollPage.scss */

.horizontal-scroll-page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f0f0f0; /* Set your desired background color */
  position: relative;
  &.active {
    position: fixed;
    top: 0;
    left: 0;
  }
  .horizontal-scroll-content {
    position: relative;
    display: flex;
    white-space: nowrap;
    transform: translateX(0);
    transition: transform 0.5s ease-in-out; /* Add a smooth transition effect */
    height: 100%;
    width: 800vw;
    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
    > div {
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      &.slide {
        position: relative;
        background: #12A185;
        overflow: hidden;
        // &:before {
        //   content: '';
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   background-position: center;
        //   background-image: url('./../assets/texture.jpg');
        //   background-size: cover;
        //   background-repeat: no-repeat;
        //   width: 100%;
        //   height: 100%;
        //   z-index: 1;
        //   mix-blend-mode: multiply;
        // }
        .next-slide, .prev-slide {
          position: absolute;
          top: 40%;
          right: 0px;
          transform: translateY(-50%);
          cursor: pointer;
          transition: 0.2s ease-in-out;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 200px;
          padding: 10px 20px;
          border-radius: 10px 0 0 10px;
          background: rgba(239, 234, 220, 0.87);
          box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.15);
          transition: 0.2s ease-in-out;
          z-index: 9;
          // @media (max-width: $breakpoint-tablet) {
          //   top: 65%;
          // }
          &:hover {
              transform: translateY(-53%);
          }
          p {
            color: #511B1B;
            font-size: 22px;
            line-height: 24px;
            font-family: 'space-grotesk-variable', sans-serif;
            text-align: center;
            font-weight: bold;
            margin-right: 10px;
            margin-top: 3px;
          }
          img {
            position: relative;
            width: 50px;
            margin: 5px auto 0;
          }
        }
        .prev-slide {
          right: auto;
          left: 0;
          border-radius: 0 10px 10px 0;
          img {
            transform: scale(-1, 1);
          }
        }
        .content {
          position: relative;
          padding: 50px 20px 0;
          z-index: 2;
          width: 100%;
          max-width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          .illustration {
            position: relative;
            max-width: 100%;
            margin: 0 auto;
            width: 800px;
            height: 800px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              position: absolute;
              top:  10%;
              left: 0;
              width: 98%;
              animation-iteration-count: infinite;
              @media (max-width: $breakpoint-tablet) {
                top: 20%;
              }
              &.foreground {
                // right: 0;
                z-index: 2;
                animation-duration: 5s;
                animation-name: bounceFloatForeground;
              }
              &.background {
                // top: 0;
                // left: 0;
                z-index: 1;
                animation-duration: 10s;
                animation-name: bounceFloatBackground;
              }
            }
          }
          .slide-info {
            display: flex;
            justify-content: flex-start;
            justify-content: center;
            max-width: 100%;
            flex-wrap: wrap;
            position: absolute;
            z-index: 3;
            .index {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 20px;
              background: #248065;
              border-radius: 50%;
              padding: 10px 0 0;
              width: 65px;
              height: 65px;
              p {
                color: #FFE6CE;
                font-family: "space-grotesk-variable";
                font-size: 38px;
                font-weight: bold;
              }
            }
            h3 {
              text-align: center;
              color: #FFE6CE;
              font-family: Tuppence;
              font-size: 65px;
              font-style: normal;
              font-weight: 700;
              line-height: 60px; /* 105.263% */
              width: auto;
              @media (max-width: $breakpoint-tablet) {
                font-size: 40px;
              }
            }
          }
        }
        &.slide-1 {
          // background: #1289AB;
          .content {
            opacity: 0; /* Set initial opacity to 0 */
            transition: opacity 1s ease-in-out;
          }
          &.active {
            .employee-testimonials {
              transform: translateY(200%);
              transition: transform bottom 1.5s ease-in-out;
            }
          }
          &.decay {
            .employee-testimonials {
              transition: transform 0.4s ease-in-out;
            }
          }
          &.fade-in {
            .content, .employee-testimonials {
              opacity: 1; /* Set opacity to 1 for the fade-in effect */
            }
            &.active {
              .employee-testimonials {
                transform: translateY(0);
              }
            }
          }
        }
        .circle {
          overflow: hidden;
          width: 800px;
          height: 800px;
          background-color: #0074d9;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            color: #fff;
          }
        }
        .employee-testimonials {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          // flex-direction: row;
          // gap: 30px;
          display: block;
          background-color: #96E1C6;
          background-color: rgba(150, 225, 198, 0.8);
          position: absolute;
          bottom: 0;
          left: 0;
          transform: translateY(150%);
          width: 100%;
          // padding: 20px 50px 75px;
          z-index: 2;
          transition: 1s ease-in-out;
          transition-delay: 0.3s;
          transition-property: transform;
          &.inactive {
            padding: 20px 50px 30px;
            height: auto;
          }
          &.active {
            padding: 60px 50px 75px;
            min-height: 50%;
          }
          &.decay {
            transition-delay: 0;
          }
          .carousel-root {
            &.active {
              opacity: 1;
              height: auto;
              transition: 1.0s ease-in-out;
            }
            &.inactive {
              opacity: 0;
              height: 0;
              transition: 0;
            }
            .carousel {
              overflow: visible;
              padding-bottom: 20px;
               .control-dots {
                 bottom: -50px;
                 .dot {
                   background: #FE8874;
                   width: 21px;
                   height: 21px;
                   opacity: 0.7;
                   transition: 0.25s ease-in;
                   &.selected {
                     opacity: 1;
                   }
                 }
               }
               .control-arrow {
                 &.control-prev, &.control-next {
                   top: auto;
                   bottom: -65px;
                   &:before {
                     border: none;
                     background-size: 50px 50px;
                     height: 50px;
                     width: 50px;
                   }
                   &:hover {
                     background: none;
                   }
                 }
                 &.control-prev {
                   left: calc(50% - 150px);
                   &:before {
                     background-image: url('./../assets/carousel-prev.svg');
                   }
                 }
                 &.control-next {
                   right: calc(50% - 150px);
                   &:before {
                     background-image: url('./../assets/carousel-next.svg');
                   }
                 }
               }
             }
          }
          // overflow-x: hidden;
          &:before {
            content: '';
            position: absolute;
            top: -120px;
            left: -40px;
            // background-image: url('./../assets/employee-dec-light-green.svg');
            background-image: url('./../assets/employee-dec-with-border.png');
            opacity: 0.8;
            background-size: cover;
            background-repeat: no-repeat;
            width: 110%;
            height: 120px;
          }
          .employee-display {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 40px;
            &.active {
              opacity: 1;
              height: auto;
              transition: 1.0s ease-in-out;
            }
            &.inactive {
              opacity: 0;
              height: 0;
              transition: 0;
            }
            .employee {
              width: 33%;
            }
          }
          .employee {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            position: relative;
            width: 100%;
            >div {
              margin: 0 auto;
              word-wrap: break-word;
              white-space: normal;
            }
            .title {
              p {
                font-size: 12px;
                font-weight: bold;
                text-transform: uppercase;
                color: #007048;
                font-family: "space-grotesk-variable";
              }
            }
            .name {
              margin-bottom: 10px;
              p {
                font-size: 24px;
                color: #272C2B;
                font-family: Tuppence;
              }
            }
            .quote {
              text-wrap: wrap;
              p {
                position: relative;
                font-size: 14px;
                height: 100%;
                color: #272C2B;
                font-family: "space-grotesk-variable";
                img {
                  position: relative;
                  top: -3px;
                }
              }
            }
          }
          .expand-collapse {
            display: block;
            width: auto;
            margin:  10px auto;
            transition: 0.2s ease-in;
            transform-origin: center;
            cursor: pointer;
            &.active {
              transform: rotate(180deg);
              margin: -20px auto 40px;
            }
          }
          .hide-employees {
            position: absolute;
            top: 5px;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
            p {
              font-size: 22px;
              font-family: Tuppence;
              color: #272C2B;
            }
          }
          .expand-employees {
            text-align: center;
            margin-bottom: 20px;
            cursor: pointer;
            p {
                font-size: 28px;
                font-family: Tuppence;
                color: #272C2B;
                word-wrap: break-word;
                white-space: normal;
              span.underline {
                text-decoration: underline;
              }
            }
          }
        }

        .progress-bar {
          display: none;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 10px;
          background-color: #EB6E6D;
          transition: .2s ease;
          z-index: 9;
        }
        &.slide-7 {
          padding: 40px 0;
          background: #248065;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &:before {
            display: none;
          }
          h2 {
            position: relative;
            font-size: 40px;
            z-index: 2;
            font-weight: bold;
            font-family: Tuppence;
            color: #F1EBDD;
            position: relative;
            &:after {
              content: '';
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              border-radius: 41px;
              background: #DEFFF9;
              filter: blur(50px);
              opacity: 0.3;
              width: 100%;
              height: 100%;
              z-index: -1;
            }
          }
        }

        &.active {
          .employee-testimonials {
            // bottom: 0;
            transform: translateY(0);
          }
          &.decay {
            .employee-testimonials {
              // bottom: -100%;
              transform: translateY(200%) !important;
            }
          }
        }
      }
    }

    .container {
      width: 100%;
      h2 {
        width: 100%;
        text-align: center;
        text-wrap: wrap;
        font-family: Tuppence;
        margin: 0 auto 50px;
        @media (max-width: $breakpoint-tablet) {
          font-size: 30px !important;
        }
      }
      .game-board {
        position: relative;
        width: 100%;
        max-width: 875px;
        height: 500px;
        margin: 0 auto;
        z-index: 3;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          background-position: center;
          background-image: url('./../assets/game-path.png');
          background-size: contain;
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
        }

        .option {
          width: 120px;
          height: 120px;
          position: absolute;
          z-index: 2;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          .circle-dec {
            position: relative;
            overflow: hidden;
            width: 120px;
            height: 120px;
            background-color: #12A185;
            border-radius: 50%;
            // border: 15px solid white;
            box-shadow: 4px 7px 20px 0px #575757;
            img {
              height: 100%;
              object-fit: cover;
              width: 100%;
            }
          }
          .board-img {
            width: 140px;
          }
          .label {
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            background: #FFFCF2;
            color: #000;
            border-radius: 12px;
            padding: 5px 10px;
            font-family: Tuppence;
            font-weight: bold;
            font-size: 16px;
            color: #191923;
            width: 200px;
            text-align: center;
            word-wrap: break-word;
            white-space: normal;
          }
          &:hover {
            transform: scale(1.1);
          }
          &.option-1 {
            top: 70px;
            left: 140px;
          }
          &.option-2 {
            top: 290px;
            right: 65%;
          }
          &.option-3 {
            top: 85px;
            right: 40%;
          }
          &.option-4 {
            top: 170px;
            right: 20%;
          }
          &.option-5 {
            top: 290px;
            right: 35%;
            .label {
              bottom: -40px;
            }
          }
          &.option-6 {
            top: 310px;
            right: 6%;
          }
        }
      }
    }
  }

  .resize-display {
    display: none;
    position: relative;
    background: #12a185;
    height: 100vh;
    padding: 50px 0;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-position: center;
      background-image: url('./../assets/texture.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      z-index: 1;
      mix-blend-mode: multiply;
    }
    p {
      color: #FFE6CE;
      font-family: Tuppence;
      font-weight: 700;
      font-size: 24px;
      text-align: center;
    }
    @media (max-width: $breakpoint-mobile) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

}

@keyframes modalFade {
  0%   {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 800px;
  max-width: 90vw;
  min-height: 500px;
  background-color: #F1EBDD;
  border-radius: 115px;
  padding: 30px;
  animation-name: modalFade;
  animation-duration: 0.4s;
  .close {
    position: absolute;
    right: 50px;
    top: 50px;
  }
  .modal-content {
    text-align: center;
    .selection {
      margin-bottom: 30px;
      .illustration {
        position: relative;
        width: 300px;
        height: 300px;
        border-radius: 50%;
        border: 5px solid #7BD4B9;
        background-color: #12A185;
        margin: 0 auto -50px;
        overflow: hidden;
        &:before {
          content: '';
          position: absolute;
          top: 15px;
          left: -45px;
          background-image: url('./../assets/two-stars.svg');
          background-size: contain;
          background-repeat: no-repeat;
          height: 75px;
          width: 75px;
        }
        &:after {
          content: '';
          position: absolute;
          bottom: 45px;
          right: -75px;
          background-image: url('./../assets/three-stars.svg');
          background-size: contain;
          background-repeat: no-repeat;
          height: 75px;
          width: 75px;
        }
        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .selection-name {
        padding: 12px 10px 18px;
        border-radius: 8px;
        background: #F1EBDD;
        box-shadow:  0 10px 15px -15px #333;
        position: relative;
        z-index: 3;
        p {
          color: #025154;
          text-align: center;
          font-family: Tuppence;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px; /* 75% */
        }
      }
    }
    h3 {
      font-size: 28px;
      margin-bottom: 20px;
      font-family: Tuppence;
      span {
        font-size: 48px;
        display: block;
      }
    }
    p {
      color: #033837;
      font-size: 14px;
      font-family: "space-grotesk-variable";
      text-decoration: none;
      cursor: pointer;
    }
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      label {
        width: 100%;
        color: #1E1E1E;
        font-family: Tuppence;
        font-size: 28px;
        input {
          width: 300px;
          // border-radius: 25px;
          border: none;
          padding: 10px;
          margin-left: 10px;
          border-radius: 3px 3px 3px 0px;
        }
      }
      .submit-donation, a.exit-experience {
        margin-top: 30px;
        border-radius: 13px;
        border: none;
        background: #FE8874;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        font-family: "space-grotesk-variable";
        padding: 15px 30px;
        box-shadow:  -3px 3px 1px 1px #511B1B;
        cursor: pointer;
      }
    }
    a.exit-experience {
      display: inline-block;
      text-align: center;
      max-width: 200px;
      text-decoration: none;
      margin: 30px 0 auto;
      border-radius: 13px;
      border: none;
      background: #FE8874;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      font-family: "space-grotesk-variable";
      padding: 15px 30px;
      box-shadow:  -3px 3px 1px 1px #511B1B;
      cursor: pointer;
    }
  }
  .modal-thank-you {
    width: 90%;
    text-align: center;
    img {
      display: block;
      width: 100%;
      max-width: 350px;
      margin: 0 auto;
    }
    p {
      margin-top: 30px;
      strong {
        font-weight: 500;
      }
    }
  }


  @media (max-width: $breakpoint-mobile) {
    border-radius: 25px;
    .close {
      right: 30px;
      top: 30px;
    }
    .modal-content {
      .selection {
        .illustration {
          margin: 0 auto -20px;
          width: 150px;
          height: 150px;
        }
        .selection-name {
          p {
            font-size: 24px;
          }
        }
      }
      h2 {
        font-size: 24px;
      }
    }
  }
}
