@import '/src/styles/variables.scss';

.client-trust {
  padding: 100px 0;
  .trust-items {
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-top: 50px;
  }
}
