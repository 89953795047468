@import '/src/styles/variables.scss';

.newsletter-display {
  .entry {
    padding: 100px 0;
    .desktop-display-only {
      display: block;
      @media (max-width: $breakpoint-mobile) {
        display: none;
      }
    }
    .mobile-display-only {
      display: none;
      @media (max-width: $breakpoint-mobile) {
        display: block;
      }
    }
    &:nth-child(even) {
      background-color: $brandlightbg;
    }
    .content-container {
      .entry-content {
        h2 {
          font-size: 45px;
          font-family: $brandheader;
          margin-bottom: 40px;
          @media (max-width: $breakpoint-mobile) {
            font-size: 35px;
          }
        }
        h3 {
          font-size: 24px;
          margin-bottom: 20px;
        }
        p {
          font-size: 18px;
          font-family: $brandbody;
          sup {
            font-size: 10px;
            vertical-align: super;
          }
          &.date {
            position: relative;
            font-size: 20px;
            margin-bottom: 20px;
          }
          p {
            margin-bottom: 20px;
          }
          strong {
            font-weight: 600;
          }
          em {
            font-style: italic;
          }
          a {
            color: $brandgreen;
          }
        }
        .tag {
          border-radius: 20px;
          padding: 5px 20px;
          text-transform: uppercase;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 700;
          line-height: 29px;
          margin-bottom: 20px;
          &.work {
            border: 1px solid $brandgreen;
            color: $brandgreen;
          }
          &.culture {
            border: 1px solid $brandblue;
            color: $brandblue;
          }
          &.people {
            border: 1px solid $brandpurple;
            color: $brandpurple;
          }
        }
        .open-video-modal, .read-more {
          border-radius: 20px;
          background-color: #288428;
          box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
          color: #fff;
          display: inline-flex;
          padding: 2px 16px 4px 16px;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 700;
          line-height: 29px;
          transition: 0.2s ease;
          &:hover {
            filter: brightness(90%);
          }
        }
      }
    }
    &.option1 {
      .entry-media {
        &.full {
          img, video {
            width: 100%;
          }
        }
        &.contained {
          height: 500px;
          @media (max-width: $breakpoint-mobile) {
            height: 300px;
            margin: 0 auto;
          }
          img, video  {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        &.mobile-display {
          display: none;
          @media (max-width: $breakpoint-mobile) {
            display: block;
            margin-bottom: 20px;
          }
        }
        &.desktop-display {
          display: block;
          @media (max-width: $breakpoint-mobile) {
            display: none;
          }
        }
      }
      .content-container {
        &.with-featured-image {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 50px;
          @media (max-width: $breakpoint-mobile) {
            flex-direction: column;
          }
          .entry-media {
            width: 50%;
            @media (max-width: $breakpoint-mobile) {
              width: 100%;
              height: 300px;
            }
            &.full {
              @media (max-width: $breakpoint-mobile) {
                height: auto;
              }
            }
          }
        }
        .entry-content {
          width: 50%;
          @media (max-width: $breakpoint-mobile) {
            width: 100%;
          }
        }
      }
      .additional-images {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 30px;
        .additional-image {
          img {
            width: 100%;
          }
        }
      }
    }
    &.option2 {
      .entry-content {
        .post-header {
          width: 50%;
          @media (max-width: $breakpoint-mobile) {
            width: 100%;
          }
        }
        .point {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 50px;
         @media (max-width: $breakpoint-mobile) {
            flex-direction: column;
            gap: 10px;
          }
          &:not(:last-child) {
            padding-bottom: 10px;
            margin-bottom: 20px;
            @media (max-width: $breakpoint-mobile) {
              padding-bottom: 5px;
              margin-bottom: 50px;
              }
          }
          .image {
            width: 50%;
            height: 300px;
            margin: 0 auto 20px;
            @media (max-width: $breakpoint-mobile) {
             width: 100%;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .point-copy {
            width: 50%;
            @media (max-width: $breakpoint-mobile) {
              width: 100%;
            }
          }
        }
      }
    }
    &.option3 {
      .entry-content {
        .subentry {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row-reverse;
          gap: 50px;
          &:not(:last-child) {
              margin-bottom: 50px;
            }
          @media (max-width: $breakpoint-mobile) {
            flex-direction: column;
            gap: 20px;
          }
          &:nth-child(even) {
            flex-direction: row;
            @media (max-width: $breakpoint-mobile) {
              flex-direction: column;
              gap: 20px;
            }
          }
          .image {
            width: 33%;
            @media (max-width: $breakpoint-mobile) {
              width: 100%;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: 100%;
            }
          }
          .subentry-content {
            width: 66%;
            @media (max-width: $breakpoint-mobile) {
              width: 100%;
            }
          }
        }
      }
    }
    &.option4 {
      .entry-content {
        h2 {
          max-width: 908px;
        }
        .block {
          margin-bottom: 50px;
          &.article-copy-block {
            max-width: 908px;
            &.with-image {
              max-width: 100%;
            }
            ul {
              margin: 30px 0;
              padding-left: 20px;
              li {
                list-style-type: disc;
                list-style-position: outside;
                margin-bottom: 20px;
              }
            }
            .body-copy {
              &.with-image {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 50px;
                @media (max-width: $breakpoint-mobile) {
                  display: block;
                }
                >p, img {
                  width: 45%;
                  @media (max-width: $breakpoint-mobile) {
                    width: 100%;
                  }
                }
                >p {
                  @media (max-width: $breakpoint-mobile) {
                    margin-bottom: 50px;
                  }
                }
                &.smaller-image {
                  >p {
                    width: 75%;
                    @media (max-width: $breakpoint-mobile) {
                      width: 100%;
                    }
                  }
                  img {
                    width: 25%;
                    max-width: 380px;
                    @media (max-width: $breakpoint-mobile) {
                      display: block;
                      width: 100%;
                      max-width: 300px;
                      margin: 0 auto;
                    }
                  }
                }
                &.reverse-display {
                  flex-direction: row-reverse;
                }
                &.mobile-reverse-display {
                  @media (max-width: $breakpoint-mobile) {
                    display: flex;
                    flex-direction: column-reverse;
                  }
                }
                &.top {
                  align-items: flex-start;
                }
                &.center {
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
