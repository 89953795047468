@import '/src/styles/variables.scss';
//Typekit for aglet font
@import url("https://use.typekit.net/xec6uvo.css");

.home-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  background-size: cover;
  background-image: none;
  position: relative;
  height: 100vh;
  min-height: 728px;
  background: #1F1F1F;
  overflow: hidden;
  @media (max-width: $breakpoint-mobile) {
    min-height: auto;
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    object-fit: cover;
    object-position: center center;
    z-index: 0;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    max-width: 100%;
    .content {
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      width: 678px;
      max-width: 100%;
      margin: 15% auto;
      padding: 0 15px;
      box-sizing: border-box;
      text-align: center;
      h1 {
        color: #fff;
        font-size: 60px;
        font-family: $aglet;
        text-transform: uppercase;
        margin: 20px 0;
        @media (max-width: $breakpoint-mobile) {
          font-size: 40px;
        }
        img {
          max-width: 100%;
        }
      }
      p {
        font-family: $brandbody;
        font-size: 20px;
        line-height: 30px;
        margin: 0 auto;
        font-weight: 700;
        color: #fff;
        text-align: center;
        @media (max-width: $breakpoint-mobile) {
          font-size: 18px;
        }
        span {
          color: $brandgreenaccessible;
        }
      }
    }
  }
}
