@import '/src/styles/variables.scss';

.intro {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #12A185;
  &:before, &:after {
    content: '';
    position: absolute;
  }
  &:before {
    top: 0;
    left: 200px;
    width: 300px;
    height: 100%;
    background: #248065;
    z-index: 1;
  }
  &:after {
    top: 200px;
    left: 0;
    width: 100%;
    height: 300px;
    background: #1D8B6B;
    z-index: 2;
  }
  .logo {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
    max-width: 300px;
  }
  .brand {
    position: absolute;
    bottom: 0;
    right: 100px;
    z-index: 9;
  }
  .container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    z-index: 3;
    .gift-tag {
      position: relative;
      background-color: #F1DEC8;
      transform: rotate(-10deg);
      padding: 20px;
      box-shadow: 0px 4px 14px 8px rgba(0, 0, 0, 0.10);
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -149px;
        width: 0;
        height: 100%;
        border-style: solid;
        border-width: 130px 150px 130px 0;
        border-color: transparent #F1DEC8 transparent transparent;
      }
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: -85px;
        transform: translateY(-50%);
        background: #12A185;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        box-shadow: inset -2px 5px 8px rgba(0, 0, 0, 0.3)
      }
      .border {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 20px 0px;
        gap: 30px;
        border: 3px dashed #FE8874;
        background: #F1EBDD;
        // &:before {
        //   content: '';
        //   position: absolute;
        //   top: 15px;
        //   left: 15px;
        //   background-image: url('./../assets/tag-diamonds-2.svg');
        //   background-size: contain;
        //   background-repeat: no-repeat;
        //   height: 75px;
        //   width: 75px;
        // }
        // &:after {
        //   content: '';
        //   position: absolute;
        //   bottom: 15px;
        //   right: 15px;
        //   background-image: url('./../assets/tag-diamonds.svg');
        //   background-size: contain;
        //   background-repeat: no-repeat;
        //   width: 75px;
        //   height: 75px;
        // }
        .title, .intro-copy {
          width: 30%;
        }
        .intro-copy {
          width: 60%;
        }
        .title {
          position: relative;
          // &:after {
          //   content: '';
          //   position: absolute;
          //   top: -20px;
          //   left: -40px;
          //   background-image: url('./../assets/tag-diamonds-2.svg');
          //   background-size: contain;
          //   background-repeat: no-repeat;
          //   width: 75px;
          //   height: 75px;
          // }
          h1 {
            position: relative;
            font-family: Tuppence;
            color: #007048;
            z-index: 1;
            font-size: 60px;
            font-weight: bold;
          }
        }
        .intro-copy {
          p {
            font-family: "space-grotesk-variable";
            font-size: 14px;
            strong {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
