@import '/src/styles/variables.scss';

.explore {
  display: block;
  position: relative;
  padding: 100px 0;
  @media (max-width: $breakpoint-tablet) {
    padding: 100px 0 200px;
  }
  @media (max-width: $breakpoint-mobile) {
    padding: 100px 0 150px;
  }
  .container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    align-items: normal;
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      gap: 0;
    }
    .description {
      width: 60%;
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        max-width: 800px;
        margin:0 auto 50px;
        text-align: center;
      }
      h2, p {
        color: #079952
      }
      h2 {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        gap: 20px;
        // animation: float 8s ease-in-out infinite;
        // animation-direction: alternate-reverse;
        @media (max-width: $breakpoint-tablet) {
          align-items: flex-start;
          justify-content: center;
        }
        img {
          width: 150px;
          height: 150px;
          animation: star-rotate-scale 4.5s ease-in-out infinite;
          @media (max-width: $breakpoint-tablet) {
            width: 30px;
            height: 30px;
          }
        }
      }
      p {
        padding-left: 175px;
        color: #414141;
        @media (max-width: $breakpoint-tablet) {
          padding-left: 0;
        }
      }
    }
    .trip-stats {
      margin-top: 50px;
      padding-left: 130px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: flex-end;
      width: auto;
      gap: 30px;
      @media (max-width: $breakpoint-tablet) {
        justify-content: space-evenly;
        padding: 0;
      }
      .stat {
        display: flex;
        flex-direction: column;
        align-items: center;
        // animation: float 10s ease-in-out infinite;
        // animation-direction: alternate-reverse;
        @media (max-width: $breakpoint-tablet) {
          max-width: 200px;
        }
        @media (max-width: $breakpoint-mobile) {
          max-width: 100px;
        }
        img {
          margin-bottom: 10px;
        }
        .number, .label {
          font-size: 22px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
        }
        .number {
          font-family: "new-hero", sans-serif;
          font-weight: 900;
          font-size: 28px;
        }
        .label {
          @media (max-width: $breakpoint-tablet) {
            font-size: 14px;
          }
        }
        &.adventures {
          animation-duration: 2s;
          .number, .label {
            color: #EE3B46;
          }
        }
        &.states {
          animation-duration: 2.5s;
          .number, .label {
            color: #CA8B30;
          }
        }
        &.countries {
          animation-duration: 3s;
          .number, .label {
            color: #0B65B2;
          }
        }
      }
    }
    .examples {
      width: 40%;
      display: flex;
      flex-direction: row;
      gap: 50px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      position: relative;
      // animation: float 3s ease-in-out infinite;
      // animation-direction: alternate-reverse;
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        align-items: flex-start;
        height: 300px;
      }
      .example {
        // animation: float 3s ease-in-out infinite;
        // animation-direction: alternate-reverse;
        text-align: center;
        position: absolute;
        @media (max-width: $breakpoint-tablet) {
          width: 400px;
          height: 400px;
        }
        @media (max-width: $breakpoint-mobile) {
          width: 300px;
          height: 300px;
        }
        img {
          width: 100%;
        }
      }
    }
  }
  .background-media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .circle-burst {
      position: absolute;
      &.green {
        top: 70%;
        left: -5%;
        @media (max-width: $breakpoint-tablet) {
          display: none;
        }
      }
      &.yellow {
        top: 90%;
        left: 95%;
        @media (max-width: $breakpoint-tablet) {
          display: none;
        }
        img {
          &.burst {
            top: -20%;
            left: -30%;
          }
        }
      }
    }
  }
}
