@import 'src/styles/_variables.scss';

.hero {
  background-size: cover;
  background-position: center;
  .main-hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 100px 0;
    text-align: center;
    h1 {
      font-size: 18px;
      text-transform: uppercase;
    }
    h2 {
      font-size: 65px;
      font-weight: 700;
    }
  }
  .additional-hero-content {
    display: flex;
    justify-content: center;
    align-items: center;
    .text-block-1,
    .text-block-2 {
      flex: 1;
      padding: 0 20px;
    }
  }
}
