@import '/src/styles/variables.scss';

.values {
  position: relative;
  padding: 75px 0;
  .black-bar {
    height: 8px;
    background-color: black;
    width: 400px;
    max-width: 100%;
    margin-bottom: 10px;
  }
  .values-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    .value {
      width: 30%;
      @media (max-width: $breakpoint-mobile) {
        width: 100%;
      }
      h3 {
        color: $brandgreen;
        font-size: 24px;
        font-weight: bold;
        margin: 40px 0 15px 0;
      }
      p {
        font-size: 18px;
        line-height: 23px;
        strong {
          font-weight: bold;
        }
      }
    }
  }
}
