@import '/src/styles/variables.scss';

.case-study-samples {
  position: relative;
  background: $branddarkbg;
  padding: 50px 0;
  .container {
    .row {
      .case-study {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 500px;
        width: 100%;
        text-decoration: none;
        background-repeat: no-repeat;
        background-size: cover;
        transition: 0.3s ease;
        overflow: hidden;
        @media (max-width: $breakpoint-mobile) {
          min-height: 300px;
        }
        &:hover {
          .background-media {
            &:after {
              background-color: rgba(0, 0, 0, 0.6);
            }
            img {
              transform: scale(1.05);
            }
          }
        }
        .background-media {
          position: absolute;
          height: 100%;
          width: 100%;
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            transition: 0.3s ease-out;
          }
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center center;
            transition: 0.5s ease-out;
          }
        }
        .case-study-information {
          position: relative;
          text-align: center;
          z-index: 1;
          h4 {
            color: #fff;
            font-size: 42px;
            margin-bottom: 10px;
            @media (max-width: $breakpoint-mobile) {
              font-size: 25px;
            }
          }
          p {
            color: $brandgreenaccessible;
            font-family: $brandheader;
            font-style: italic;
            font-size: 31px;
            @media (max-width: $breakpoint-mobile) {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
