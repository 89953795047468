@import '/src/styles/variables.scss';

.case-study-video {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 40px 0;
  .container {
    width: 100%;
    .video-container-responsive {
      position: relative;
      display: block;
      width: 100%;
      overflow: hidden;
      &:before {
        display: block;
        content: "";
        padding-top: 56.25%;
      }
      iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  }
}
