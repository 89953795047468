@import '/src/styles/variables.scss';


.reflection {
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  @media (max-width: $breakpoint-mobile) {
    min-height: auto;
    padding: 100px 0;
  }
  .container {
    .reflection-note {
      width: 100%;
      max-width: 850px;
      h2, p, button {
        text-align: center;
        color: #F85B44;
      }
      h2 {
        margin-bottom: 20px;
      }
      p {
        padding: 0 120px;
        color: #414141;
        @media (max-width: $breakpoint-tablet) {
          padding: 0;
        }
      }
      button {
        background-color: transparent;
        color: #F55651;
        text-align: center;
        cursor: pointer;
        font-weight: 700;
      }
    }
  }
  .background-media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .flower {
      &.red {
        position: absolute;
        top: 0;
        left: -10%;
        @media (max-width: 1325px) {
          left: -30%;
        }
        @media (max-width: $breakpoint-mobile) {
          display: none;
        }
      }
    }
    .star {
      &.yellow {
        &.one {
          position: absolute;
          top: 70%;
          left: 20%;
          @media (max-width: $breakpoint-mobile) {
            top: 85%;
            left: 10%;
          }
        }
        &.two {
          position: absolute;
          top: 5%;
          left: 80%;
          @media (max-width: $breakpoint-mobile) {
            top: 10%;
          }
        }
      }
    }
    .circle-burst {
      &.blue {
        position: absolute;
        top: 75%;
        left: 90%;
        img {
          &.burst {
            left: -20%;
            top: -20%;
          }
        }
      }
    }
  }
}
