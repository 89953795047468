@import '/src/styles/variables.scss';

.portfolio-live-view {
  position: relative;
  padding: 100px 0;
  background: #fff;
  .cta {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
    max-width: 100%;
    margin: 0 auto;
    padding: 10px 20px;
    font-size: 30px;
    color: #000;
    font-family: $brandheader;
    text-decoration: none;
    background: #fff;
    border: 1px solid #000;
    border-radius: 30px;
    transition: 0.3s ease;
    @media (max-width: $breakpoint-mobile) {
      font-size: 20px;
      width: 300px;
    }
    &:hover {
      color: $brandgreen;
    }
    img {
      width: 30px;
      margin-left: 10px;
      @media (max-width: $breakpoint-mobile) {
      width: 20px;
      }
    }
  }
}
