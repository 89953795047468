@import '/src/styles/variables.scss';

// @function randomDec($min, $max) {
//   $rand: random();
//   $randomDec: $min + floor($rand * (($max - $min) * 0.05));

//   @return $randomDec;
// }


@keyframes jingleBell {
  0%   {
    transform: rotate(-9deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

@keyframes birdLeft {
  0%   {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-10deg) translate(2px, -20px);
  }
  32% {
    transform: rotate(-10deg) translate(-2px, -20px);
  }
  34% {
    transform: rotate(-10deg) translate(2px, -20px);
  }
  36% {
    transform: rotate(-10deg) translate(-2px, -20px);
  }
  38% {
    transform: rotate(-10deg) translate(2px, -20px);
  }
  40% {
    transform: rotate(-10deg) translate(-2px, -20px);
  }
  42% {
    transform: rotate(-10deg) translate(2px, -20px);
  }
  44% {
    transform: rotate(-10deg) translate(-2px, -20px);
  }
  46% {
    transform: rotate(-10deg) translate(2px, -20px);
  }
  48% {
    transform: rotate(-10deg) translate(-2px, -20px);
  }
  50% {
    transform: rotate(-10deg) translate(2px, -20px);
  }
  52% {
    transform: rotate(-10deg) translate(-2px, -20px);
  }
  54% {
    transform: rotate(-10deg) translate(2px, -20px);
  }
  56% {
    transform: rotate(-10deg) translate(-2px, -20px);
  }
  58% {
    transform: rotate(-10deg) translate(2px, -20px);
  }
  60% {
    transform: rotate(-10deg) translate(-2px, -20px);
  }

  100% {
    transform: rotate(0deg) translateY(0);
  }
}

@keyframes birdRight {
  0%   {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(10deg) translate(-2px, -20px);
  }
  32% {
    transform: rotate(10deg) translate(2px, -20px);
  }
  34% {
    transform: rotate(10deg) translate(-2px, -20px);
  }
  36% {
    transform: rotate(10deg) translate(2px, -20px);
  }
  38% {
    transform: rotate(10deg) translate(-2px, -20px);
  }
  40% {
    transform: rotate(10deg) translate(2px, -20px);
  }
  42% {
    transform: rotate(10deg) translate(-2px, -20px);
  }
  44% {
    transform: rotate(10deg) translate(2px, -20px);
  }
  46% {
    transform: rotate(10deg) translate(-2px, -20px);
  }
  48% {
    transform: rotate(10deg) translate(2px, -20px);
  }
  50% {
    transform: rotate(10deg) translate(-2px, -20px);
  }
  52% {
    transform: rotate(10deg) translate(2px, -20px);
  }
  54% {
    transform: rotate(10deg) translate(-2px, -20px);
  }
  56% {
    transform: rotate(10deg) translate(2px, -20px);
  }
  58% {
    transform: rotate(10deg) translate(-2px, -20px);
  }
  60% {
    transform: rotate(10deg) translate(2px, -20px);
  }

  100% {
    transform: rotate(0deg) translateY(0);
  }
}


@keyframes snowflake {
  0%   {
    transform: rotate(-9deg) translate(2px, 0);
    opacity: 0.3;
  }
  25% {
    transform: rotate(10deg) translate(-6px, 10px);
    opacity: 0.5;
  }
  50% {
    transform: rotate(-9deg) translate(5px, 15px);
    opacity: 0.25;
  }
  75% {
    transform: rotate(10deg) translate(-8px, 25px);
    opacity: 0.25;
  }
  100% {
    transform: rotate(-9deg) translate(9px, 30px);
    opacity: 0;
  }
}

.greater-purpose {
  position: relative;
  padding: 100px 0 0;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(87,198,185,1) 66%);
  overflow-y: visible;
  h2 {
    font-size: 50px;
    margin-bottom: 30px;
  }
  .the-safe-alliance-content {
    display: none;
  }
  .content {
    position: relative;
    margin: 50px auto;
    padding-bottom: 450px;
    text-align: center;
    z-index: 9;
    p {
      width: 850px;
      max-width: 100%;
      margin: 0 auto 20px;
    }
  }

  .birds-bell-display {
    display: block;
    position: relative;
    min-height: 100px;
    .bird {
      position: absolute;
      top: -20px;
      width: 150px;
      max-width: 25%;
      // animation-duration: 4s;
      // animation-iteration-count: infinite;
      // animation-direction: foward;
      &.left {
        left: 5%;
        // animation-name: birdLeft;
      }
      &.right {
        right: 5%;
        // animation-name: birdRight;
      }
    }
    .bell {
      position: relative;
      img {
        position: absolute;
        left: 50%;
        // transform: translateX(-50%);
        max-width: 33%;
        &.bell-img {
          animation-name: jingleBell;
          animation-duration: 2s;
          animation-iteration-count: infinite;
          animation-direction: foward;
          transform-origin: top center;
          left: calc(50% - 16.5%);
        }
        &.bell-dec {
          transform: translateX(-50%);
        }
      }
    }

    img {
      display: block;
      margin: 0 auto;
      width: 500px;
      max-width: 100%;
    }
  }

  .house-scene-display {
    position: absolute;
    bottom: -150px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 2;
    img {
      position: absolute;
      &.house {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        margin: 0 auto;
        max-width: 350px;
      }
      &.flower {
        bottom: -75px;
        max-width: 25%;
       &.black {
         left: 0;
       }
       &.white {
          right: 0;
       }
      }
      &.heart {
        bottom: 350px;
        max-width: 10%;
        &.left {
          left: 35%;
          transform: translateX(-50%) rotate(-45deg);
        }
        &.right {
          right: 35%;
          transform: translateX(50%);
        }
      }
      &.bell2 {
        bottom: 150px;
        width: 90px;
        max-width: 10%;
        &.left {
          left: 25%;
          transform: translateX(-50%);
        }
        &.right {
          right: 25%;
          transform: translateX(50%) rotate(45deg);
        }
      }
    }
  }

  .snowflakes {
    position: absolute;
    bottom: -100px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 65%;
    z-index: 1;
    .snowflake {
      position: absolute;
      animation-name: snowflake;
      animation-iteration-count: infinite;
      animation-direction: foward;
      // top: percentage(random($limit: 100) / 100);
      // left: percentage(random($limit: 100) / 100);
    }
    // @for $i from 1 through 50 {
    //   .snowflake:nth-child(#{$i}) {
    //     // top: percentage(random($limit: 100) / 100);
    //     // left: percentage(random($limit: 100) / 100);
    //     // // transform: scale(random($limit: 1) * 0.{$i});
    //     // transform: scale(#{randomDec(0, $i)});
    //     animation-duration: random($limit: 4) + s;
    //   }
    // }
  }

  &:after {
    content: '';
    display: inline;
    background: url("./../assets/safe-alliance-bg.png") no-repeat center bottom/cover;
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: -199px;
  }


  @media (max-width: $breakpoint-mobile) {
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(87,198,185,1) 100%);
    h2 {
      font-size: 32px;
    }
    .content {
      padding-bottom: 150px;
    }
    .house-scene-display {
      img {
        &.house {
          max-width: 50%;
        }
        &.flower {
          bottom: 0px;
          max-width: 30%;
        }
        &.heart {
          bottom: 200px;
          &.left {
            left: 25%;
          }
          &.right {
            right: 25%;
          }
        }
        &.bell2 {
          bottom: 135px;
          &.left {
            left: 20%
          }
          &.right {
            right: 20%;
          }
        }
      }
    }
  }
}
