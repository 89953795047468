@import '/src/styles/variables.scss';

.newsletter-title {
  position: relative;
  height: 400px;
  border-bottom: 1px solid #000;
  .container {
    padding-top: 25px;
    position: relative;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      color: #1e1e1e;
      position: relative;
      display: inline-block;
      font-size: 100px;
      img {
        width: 80%;
        margin: 0 auto;
        &.desktop {
          display: inline-block;
          @media (max-width: $breakpoint-mobile) {
            display: none;
           }
        }
        &.mobile {
          display: none;
          width: 100%;
          @media (max-width: $breakpoint-mobile) {
            display: block;
           }
        }
      }
      @media (max-width: $breakpoint-mobile) {
       font-size: 60px;
      }
    }
    p {
      color: #1e1e1e;
      font-size: 30px;
      font-family: $brandbody;
      @media (max-width: $breakpoint-mobile) {
        font-size: 22px;
      }
    }
    .featured-tag {
      position: absolute;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      color: #fff;
      text-align: center;
      width: 75px;
      height: 75px;
      padding: 20px;
      background-color: green;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      z-index: -1;
      @media (max-width: $breakpoint-mobile) {
        display: none;
      }
      &.work {
        background-color: $brandgreen;
        top: 65%;
        left: 130px;
        transform: translateY(-50%) rotate(-10deg);
        @media (max-width: $breakpoint-tablet) {
          top: 65%;
          left: 20px;
        }
        @media (max-width: $breakpoint-mobile) {
          top: 50%;
          left: 30px;
        }
      }
      &.culture {
        background-color: $brandblue;
        bottom: -35px;
        left: 70%;
        transform: translateX(-50%) rotate(5deg);
        z-index: 1;
        @media (max-width: $breakpoint-tablet) {
          left: 60%;
          bottom: -30px;
        }
        @media (max-width: $breakpoint-mobile) {
          bottom: 10px;
          left: 65%;
        }
      }
      &.people {
        background-color: $brandpurple;
        top: 30%;
        right: 100px;
        transform: translateY(-50%) rotate(-10deg);
        @media (max-width: $breakpoint-tablet) {
          top: 30%;
          right: 50px;
        }
        @media (max-width: $breakpoint-mobile) {
          top: 30%;
          right: 30px;
        }
      }
    }
  }
}
