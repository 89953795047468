@import '/src/styles/variables.scss';

.portfolio-item-contact {
  position: relative;
  background: #fff;
  .container {
    position: relative;
    border-top: 1px solid #000;
    padding: 30px 0 50px;
    .back-to-projects {
      position: absolute;
      top: 30px;
      left: 0;
      @media (max-width: $breakpoint-mobile) {
        position: relative;
        text-align: center;
        top: 0;
      }
      a {
        display: flex;
        align-items: center;
        font-size: 21px;
        font-weight: 600;
        color: #000;
        text-decoration: none;
        @media (max-width: $breakpoint-mobile) {
          font-size: 14px;
          justify-content: center;
        }
        img {
          margin-right: 10px;
          @media (max-width: $breakpoint-mobile) {
            width: 30px;
          }
        }
      }
    }
    .message {
      display: block;
      text-align: center;
      margin-top: 75px;
      @media (max-width: $breakpoint-mobile) {
        margin-top: 50px;
      }
      p {
        font-size: 36px;
        font-weight: 600;
        line-height: 1.4;
        @media (max-width: $breakpoint-mobile) {
          font-size: 20px;
        }
        a {
          color: $brandgreen;
          text-decoration: underline;
        }
        br {
          display: block;
        }
      }
    }
  }
}
