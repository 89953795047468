@import '/src/styles/variables.scss';

.case-study-item-hero {
  position: relative;
  padding: 85px 0;
  background-size: cover;
  background-position: center;
  color: #fff;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .container {
    position: relative;
    z-index: 1;
    .label {
      padding: 20px 0;
      border-bottom: 2px solid #fff;
      span {
        @media (max-width: $breakpoint-mobile) {
          font-size: 30px;
        }
        display: block;
        font-size: 40px;
        line-height: 50px;
      }
    }
    .hero-content {
      padding: 30px 0;
      h1 {
        margin-bottom: 30px;
        @media (max-width: $breakpoint-mobile) {
          font-size: 40px;
        }
      }
      h2 {
        font-size: 30px;
        line-height: 20px;
        color: $brandgreenaccessible;
        font-style: italic;
        @media (max-width: $breakpoint-mobile) {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
}
