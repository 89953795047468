@import '/src/styles/variables.scss';

.portfolio-contact {
  background: #288428;
  padding: 100px 0;
  p, a {
    font-family: $brandheader;
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    @media (max-width: $breakpoint-mobile) {
      font-size: 20px;
    }
  }
}
