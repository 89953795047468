@import '/src/styles/variables.scss';

.growth {
  display: block;
  position: relative;
  padding: 50px 0 150px;
  @media (max-width: $breakpoint-mobile) {
    padding: 50px 0 100px;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
    }
    .description {
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        margin-bottom: 50px;
      }
      @media (max-width: $breakpoint-mobile) {
        margin-bottom: 0;
      }
      h2, p {
        color: #328248;
      }
      h2 {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        // animation: float 8s ease-in-out infinite;
        // animation-direction: alternate-reverse;
        @media (max-width: $breakpoint-tablet) {
          align-items: flex-start;
        }
        img {
          width: 78px;
          height: 130px;
          animation: star-rotate-scale 4.5s ease-in-out infinite;
          @media (max-width: $breakpoint-tablet) {
            width: 20px;
            height: 30px;
          }
        }
      }
      p {
        padding-left: 100px;
        color: #414141;
        @media (max-width: $breakpoint-tablet) {
          text-align: center;
          padding: 0;
        }
      }
    }
    .examples {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      gap: 50px;
      align-items: center;
      justify-content: center;
      width: 100%;
      .example {
        width: calc(33.33% - 34px);
        position: relative;
        // animation: float 3s ease-in-out infinite;
        // animation-direction: alternate-reverse;
        @media (max-width: $breakpoint-tablet) {
          width: calc(50% - 25px);
        }
        @media (max-width: $breakpoint-mobile) {
          width: calc(50% - 25px);
        }
        .group-container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          @media (max-width: $breakpoint-tablet) {
            height: auto;
            justify-content: center;
          }
          @media (max-width: $breakpoint-mobile) {
            height: auto;
          }
          .event-image-container {
            position: relative;
            width: 300px;
            height: auto;
            // background: gray;
            margin-left: -10px;
            // cursor: pointer;
          // transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

          // &:hover {
          //   transform: scale(1.05) rotate(0deg) !important;
          // }

            @media (max-width: $breakpoint-tablet) {
              width: 250px;
            }
            @media (max-width: $breakpoint-mobile) {
              width: 200px;
            }
            img {
              // display: none;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .event-icon {
            width: 100px;
            max-width: 40%;
            position: relative;
            z-index: 2;
            @media (max-width: $breakpoint-tablet) {
              width: 75px;
              max-width: 25%;
            }
            @media (max-width: $breakpoint-mobile) {
              width: 50px;
            }
          }
        }
        .label {
          text-align: center;
          h4 {
            font-family: "new-hero", sans-serif;
            font-weight: 900;
            font-size: 28px;
            line-height: 1.2;
            @media (max-width: $breakpoint-tablet) {
              font-size: 22px;
            }
            @media (max-width: $breakpoint-mobile) {
              font-size: 18px;
            }
          }
        }
        &.first-round {
          .group-container {
            .event-image-container {
              transform: rotate(10deg);
            }
            .event-icon {
              left: 20px;
            }
          }
          .label {
            h4 {
              color: #5A79DD;
            }
          }
        }
        &.aiga {
          .group-container {
            flex-direction: row-reverse;
            .event-image-container {
              transform: rotate(-10deg);
            }
            .event-icon {
              right: 20px;
            }
          }
          .label {
            h4 {
              color: #F55651;
            }
          }
        }
        &.ultra-speaking {
          .group-container {
            flex-direction: row-reverse;
            .event-image-container {
              transform: rotate(10deg);
            }
            .event-icon {
              right: 20px;
            }
          }
          .label {
            h4 {
              color: #CA8B30;
            }
          }
        }
        &.sxsw {
          .group-container {
            .event-image-container {
              transform: rotate(10deg);
            }
            .event-icon {
              left: 10px;
            }
          }
          .label {
            h4 {
              color: #079952;
            }
          }
        }
        &.womens-conference {
          .group-container {
            .event-image-container {
              transform: rotate(10deg);
            }
            .event-icon {
              left: 20px;
            }
          }
          .label {
            h4 {
              color: #F55651;
            }
          }
        }
        &.marketing-brew {
          .group-container {
            flex-direction: row-reverse;
            .event-image-container {
              transform: rotate(-10deg);
            }
            .event-icon {
              right: 20px;
            }
          }
          .label {
            h4 {
              color: #5A79DD;
            }
          }
        }
      }
    }
  }
  .background-media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .circle-burst {
      position: absolute;
      &.red {
        top: 50%;
        left: -5%;
        @media (max-width: 1380px) {
          top: 30%;
        }
        @media (max-width: $breakpoint-tablet) {
          top: 50%;
        }
        @media (max-width: $breakpoint-mobile) {
          top: 60%;
        }
      }
    }
    .star {
      position: absolute;
      &.blue {
        top: 27%;
        left: 45%;
        animation-duration: 2.5s;
        @media (max-width: $breakpoint-tablet) {
          top: 15%;
        }
      }
      &.green {
        top: 5%;
        left: 70%;
        animation-duration: 5s;
      }
    }
  }
}
