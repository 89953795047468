@import '/src/styles/variables.scss';

.photo-grid {
  position: relative;
  padding: 75px 0;
  background: $branddarkbg;
  .social-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, calc(50% - 5px)));
    gap: 10px;
    max-width: 100%;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    @media (max-width: $breakpoint-mobile) {
      grid-template-columns: repeat(auto-fill, minmax(200px, 100%))
    }
    img {
      width: 100%;
      max-width: 100%;
    }
    .social-2x {
      display: grid;
      grid-template-columns: repeat(2, 0.5fr);
      grid-gap: 10px;
    }
  }
}
