@import '/src/styles/variables.scss';

.primary-footer {
  position: relative;
  background-color: #101112;
  padding: 15px 0 0;
  @media (max-width: $breakpoint-mobile) {
    padding: 40px 0 0;
  }
  .content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $breakpoint-mobile) {
      display: block;
    }
    h3, p, a, span {
      font-family: $brandheader;
    }
    .contact {
      @media (max-width: $breakpoint-mobile) {
        margin-bottom: 30px;
      }
      h3 {
        font-size: 28px;
        color: #fff;
        border-top: 3px solid #fff;
        margin-bottom: 30px;
      }
      .details {
        p {
          font-size: 15px;
          font-weight: 600;
          line-height: 1.3;
          a {
            color: #808080;
            text-decoration: none;
          }
        }
      }
    }
    .social {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media (max-width: $breakpoint-mobile) {
        justify-content: center;
      }
      .links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 20px;
        a {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 10px 0;
          text-decoration: none;
          img {
            margin-right: 10px;
          }
          span {
            color: #808080;
            text-transform: uppercase;
            font-weight: 600;
          }
        }
      }
      .dec {
        width: 75px;
      }
    }
  }
}
