@import '/src/styles/variables.scss';

.busy {
  display: block;
  position: relative;
  padding: 100px 0;
  @media (max-width: $breakpoint-mobile) {
    padding: 50px 0;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    .description {
      width: 100%;
      max-width: 900px;
      margin-bottom: 30px;
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        margin-bottom: 0;
      }
      h2, p {
        color: #F85B44;
      }
      h2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        // animation: float 8s ease-in-out infinite;
        // animation-direction: alternate-reverse;
        @media (max-width: $breakpoint-tablet) {
          align-items: flex-start;
          margin-bottom: 10px;
        }
        img {
          width: 78px;
          height: 130px;
          animation: star-rotate-scale 4.5s ease-in-out infinite;
          @media (max-width: $breakpoint-tablet) {
            width: 20px;
            height: 30px;
          }
        }
      }
      p {
        padding-left: 120px;
        color: #414141;
        // animation: float 10s ease-in-out infinite;
        // animation-direction: alternate-reverse;
        @media (max-width: $breakpoint-tablet) {
          padding-left: 0;
          text-align: center;
        }
        // @media (max-width: $breakpoint-mobile) {
        //   padding-left: 120px;
        // }
      }
    }
    .examples {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 50px;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: center;
      margin-top: 30px;
      @media (max-width: $breakpoint-mobile) {
        justify-content: space-evenly;
      }
      @media (max-width: $breakpoint-mobile) {
        margin-top: 30px;
      }
      .example {
        // animation: float 3s ease-in-out infinite;
        // animation-direction: alternate-reverse;
        text-align: center;
        max-width: 25%;
        // @media (max-width: $breakpoint-tablet) {
        //   width: 200px;
        // }
        // @media (max-width: $breakpoint-mobile) {
        //   width: 75px;
        // }
        img {
          width: 100%;
          margin-bottom: 20px;
        }
        .number, .label {
          text-align: center;
          font-style: normal;
          font-weight: 800;
          line-height: 1.2; /* 107.692% */
        }
        .number {
          font-size: 65px;
          font-family: "new-hero", sans-serif;
          font-weight: 900;
          @media (max-width: $breakpoint-tablet) {
            font-size: 45px;
          }
          @media (max-width: $breakpoint-mobile) {
            font-size: 22px;
          }
        }
        .label {
          font-size: 30px;
          @media (max-width: $breakpoint-tablet) {
            font-size: 22px;
          }
          @media (max-width: $breakpoint-mobile) {
            font-size: 14px;
          }
        }
        &.messages {
          animation-duration: 3s;
          color: #5A79DD;
        }
        &.files {
          animation-duration: 5s;
          color: #F55651;
        }
        &.projects {
          animation-duration: 4s;
          color: #509C6A;
        }
      }
    }
  }
  .background-media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .flower {
      position: absolute;
      &.red {
        top: 50%;
        left: -250px;
        @media (max-width: 1265px) {
          top: 70%;
          left: -175px;
          width: 300px;
          height: 300px;
        }
        @media (max-width: $breakpoint-tablet) {
          top: 70%;
          left: -23%;
        }
        @media (max-width: $breakpoint-mobile) {
          display: none;
        }
      }
    }
  }
}
