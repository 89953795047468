@import '/src/styles/variables.scss';

.team {
  position: relative;
  padding: 50px 0;
  h2 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 100px;
  }
  .team-member-display {
    display: flex;
    flex-wrap: wrap;
    flex-flow: row wrap;
    align-items: flex-start;
    gap: 10px;
    .team-member {
      width: 18%;
      min-width: 200px;
      margin-bottom: 55px;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      @media (max-width: $breakpoint-mobile) {
        min-width: 48%;
      }
      .headshot {
        width: 130px;
        height: 130px;
        margin: 0 auto;
        background-size: cover;
        background-position: center;
        border-radius: 130px;
      }
      h4 {
        margin: 15px 0 0 0;
        font-size: 21px;
        color: #000;
      }
      p {
        margin: 0;
        font-size: 18px;
        font-family: $brandbody;
        line-height: normal;
        padding: 0 10px;
        color: #288428;
      }
    }
  }
}

.ReactModalPortal {
  padding: 50px;
  overflow: scroll;
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .modal-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    padding: 30px;
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column-reverse;
      padding: 20px;
    }
    > div  {
      width: 50%;
      @media (max-width: $breakpoint-mobile) {
        width: 100%;
      }
      &.headshot {
        img {
          display: block;
          width: 100%;
          max-width: 500px;
          margin: 0 auto;
          @media (max-width: $breakpoint-mobile) {
            max-width: 150px;
            border-radius: 50%;
          }
        }
      }
      &.team-member-info {
        position: relative;
        top: 10px;
        &::before {
          content: '';
          position: absolute;
          top: -10px;
          background-color: #000;
          height: 8px;
          width: 400px;
          max-width: 100%;
          @media (max-width: $breakpoint-mobile) {
            height: 4px;
            max-width: 50%;
          }
        }
        h4 {
          font-size: 36px;
        }
        p {
          font-family: $brandbody;
          font-size: 18px;
          &.job-title {
            font-size: 18px;
            font-style: italic;
            margin-top: 5px;
            margin-bottom: 20px;
            line-height: 20px;
          }
          &.bio, &.bio p {
            color: $brandgreen;
            line-height: 40px;
            @media (max-width: $breakpoint-mobile) {
              line-height: 30px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
