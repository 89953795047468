@import '/src/styles/variables.scss';

.financial {
  .primary-header, .primary-footer {
    display: none;
  }
  .placeholder {
    display: block;
    width: 100%;
    height: 1000px;
    background-color: green;
  }
}
