@import '/src/styles/variables.scss';

.case-study-motion-graphic-feature {
  position: relative;
  padding: 50px 0;
  &.extra-spacing-top {
    margin-top: 50px;
  }
  &.extra-spacing-bottom {
    margin-bottom: 50px;
  }
  video {
    width: 100%;
  }
}
