@import '/src/styles/variables.scss';

.portfolio-gallery {
  position: relative;
  padding: 50px 0;
  background: #fff;
  z-index: 3;
  .gallery-row {
    width: 100%;
    height: 435px;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    transition: 0.3s ease;
    &.height-reset {
      height: auto;
    }
    @media (max-width: $breakpoint-mobile) {
      height: 200px;
      gap: 10px;
      margin-bottom: 10px;
    }
    .gallery-image {
      &.image-contain {
        img {
          object-fit: contain;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &.row-1 {
      .gallery-image {
        width: 100%;
      }
    }
    &.row-2 {
      .gallery-image {
        width: 50%;
      }
      &.offset-left {
        .gallery-image {
          &:first-child {
            width: 33%;
          }
          &:last-child {
            width: 66%;
          }
        }
      }
      &.offset-right {
        .gallery-image {
          &:first-child {
            width: 66%;
          }
          &:last-child {
            width: 33%;
          }
        }
      }
    }
    &.row-3 {
      .gallery-image {
        width: 33%;
        height: 100%;
      }
    }
  }
}
