@import '/src/styles/variables.scss';


@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.holiday-gift {
  height: 100vh;
  overflow: hidden;
  br {
    display: block !important;
  }
  &.mobile {
    height: auto;
  }
  .front-container {
    overflow: hidden;
    transform: translateY(0);
    transition: transform 0.5s ease-in-out;
    height: 200vh;
    &.activated {
      overflow: auto;
      transform: translateY(-100vh);
      .button {
        display: none;
      }
    }
  }
  .primary-header, .primary-footer {
    display: none;
  }
  .button {
    position: fixed;
    z-index: 99;
    top: 90vh;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    text-transform: uppercase;
    font-family: Tuppence;
    img {
      display: block;
      margin: 0 auto 10px;
      animation: bounce 2s infinite;
    }
  }
}
