@import '/src/styles/variables.scss';

.portfolio-annotated-image {
  position: relative;
  padding: 50px 0;
  background: #fff;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    .copy {
      width: 40%;
      font-size: 17px;
      @media (max-width: $breakpoint-mobile) {
        width: 100%;
      }
    }
    .image {
      width: 60%;
      @media (max-width: $breakpoint-mobile) {
        width: 100%;
      }
    }
  }
  &.first {
    .container {
      flex-direction: row;
      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
      }
      .copy {
        padding-right: 40px;
        @media (max-width: $breakpoint-mobile) {
          margin-bottom: 40px;
          padding-right: 0;
        }
      }
    }
  }
  &.second {
    .container {
      flex-direction: row-reverse;
      @media (max-width: $breakpoint-mobile) {
        flex-direction: column-reverse;
      }
      .copy {
        padding-left: 40px;
        @media (max-width: $breakpoint-mobile) {
          margin-top: 40px;
          padding-left: 0;
        }
      }
    }
  }
}
