@import '/src/styles/variables.scss';

.donation-2024 {
  position: relative;
  height: auto;
  min-height: 100vh;
  padding: 150px 0;
  width: 100%;
  background-size: cover;
  background-position: bottom center;
  @media (max-width: $breakpoint-tablet) {
    min-height: auto;
  }
  .content-container {
    position: relative;
    padding: 100px 50px;
    width: 100%;
    background-size: cover;
    background-position: bottom center;
    border-radius: 52px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    .donation-note {
      width: 100%;
      max-width: 800px;
      text-align: center;
      margin: 0 auto 50px;
      h2, p {
        color: #079952;
      }
      h2 {
        margin-bottom: 30px;
      }
      p {
        color: #414141;
      }
    }
    .charities {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: flex-end;
      gap: 20px;
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 50px;
      }
      .charity {
        cursor: pointer;
        text-align: center;
        width: 265px;
        max-width: 30%;
        transition: transform 0.2s ease-in-out;
        @media (max-width: $breakpoint-mobile) {
          max-width: 100%;
        }
        .charity-logo {
          position: relative;
          margin:0 auto 20px;
          max-width: 200px;
          @media (max-width: $breakpoint-mobile) {
            max-width: 200px;
            margin: 0 auto 20px;
          }
          img.logo {
            width: 100%;
            position: relative;
            z-index: 2;
            transition: transform 0.25s ease-in-out;
          }
          .circle-burst {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100px;
            height: 100px;
            z-index: 1;
            img {
              position: absolute;
              width: 100%;
            }
          }
        }
        .charity-name {
          margin-bottom: 10px;
        }
        &:hover {
          transform: translateY(-4px);
          .charity-logo {
            img.logo {
              transform: translateY(-3px) ;
            }
          }
        }
        &.big-brothers-big-sisters {
          h3, p {
            color: #079952
          }
          .charity-logo {
            max-width: 250px;
            .circle-burst {
              top: 45%;
              left: -100%;
              .circle {
                animation: rotate-counter-clockwise 450s linear infinite, move-clockwise 25s linear infinite;
              }
              .burst {
                animation: rotate-clockwise 50s linear infinite, move-counter-clockwise 20s linear infinite;
                top: -30%;
                left: -5%;
              }
            }
          }
        }
        &.bookspring {
          // margin-top: 100px;
          @media (max-width: $breakpoint-tablet) {
            margin-top: 0;
          }
          h3, p {
            color: #5A79DD;
          }
          .charity-logo {
            .circle-burst {
              top: 0%;
              left: 130%;
              .circle {
                animation: rotate-counter-clockwise 450s linear infinite, move-clockwise 25s linear infinite;
              }
              .burst {
                animation: rotate-clockwise 50s linear infinite, move-counter-clockwise 20s linear infinite;
                top: -40%;
                left: 90%;
              }
            }
          }
        }
        &.casa {
          h3, p {
            color: #F55651;
          }
          .charity-logo {
            .circle-burst {
              top: 55%;
              left: 120%;
              .circle {
                animation: rotate-counter-clockwise 450s linear infinite, move-clockwise 25s linear infinite;
              }
              .burst {
                animation: rotate-clockwise 50s linear infinite, move-counter-clockwise 20s linear infinite;
                top: 30%;
                left: 80%;
              }
            }
          }
        }
      }
    }
  }
  .wrapping-paper-strip {
    position: absolute;
    left: 0;
    width: 100%;
    &.top {
      top: 0;
    }
    &.bottom {
      bottom: -10px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  .background-media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .flower {
      &.red {
        position: absolute;
        top: -12%;
        left: -7%;
        @media (max-width: $breakpoint-mobile) {
          top: -12%;
        }
      }
    }
    .circle-burst {
      position: absolute;
      top: 0;
      left: 80%;
      @media (max-width: $breakpoint-tablet) {
        top: -5%;
      }
      img {
        &.burst {
          top: 30%;
          left: 30%;
        }
      }
    }
  }

}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .modal-content {
    background: white;
    padding: 50px;
    border-radius: 8px;
    position: relative;
    width: 90%;
    max-width: 1000px;
    max-height: 90vh;
    overflow-y: auto;
    border-radius: 45px;
    .thank-you-message {
      text-align: center;
      max-width: 500px;
      margin: 0 auto;
    }
    .close-button {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 24px;
      background: none;
      border: none;
      cursor: pointer;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.2s;
      color: #fff;
      font-size: 50px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    .logo-container {
      text-align: center;
      margin-bottom: 50px;
      img {
        max-width: 100%;
      }
    }
    .button-container {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      button {
        width: 301px;
        height: 76px;
        border-radius: 100px;
        padding: 10px 10px;
        transition: background-color 0.2s, border-color 0.2s;
        &.decline-hoodie {
          font-size: 18px;
        }
      }
    }
    .charity-info-container {
      display: flex;
      justify-content: space-between;
      gap: 50px;
      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        gap: 50px;
      }
      .charity-info {
        text-align: left;
        .charity-name {
          font-size: 50px;
          margin-bottom: 20px;
          @media (max-width: $breakpoint-tablet) {
            font-size: 24px;
          }
        }

        .charity-summary {
          font-size: 28px;
          line-height: 1.5;
          color: #414141;
          @media (max-width: $breakpoint-tablet) {
            font-size: 16px;
          }
        }
      }
      .charity-logo {
        text-align: center;
        margin-bottom: 20px;

        img {
          max-width: 200px;
          height: auto;
        }
      }
    }
    form {
      h3, p {
        color: #3C3C3C;
        text-align: center;
      }
      p {
        font-size: 22px;
      }
      .form-group {
        margin: 50px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        @media (max-width: $breakpoint-mobile) {
          flex-direction: column;
        }
        label {
          display: none;
        }
        input, select {
          border: 3px solid #3C3C3C;
          border-radius: 100px;
          width: 301px;
          max-width: 30%;
          height: 76px;
          padding: 10px 20px;
          font-size: 20px;
          background: transparent;
          font-family:'helvetica-neue', sans-serif;
          @media (max-width: $breakpoint-mobile) {
            max-width: 100%;
          }
        }
        select {
          appearance: none; /* Remove the default arrow styling */
          -webkit-appearance: none; /* For Safari */
          -moz-appearance: none; /* For Firefox */
          background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 viewBox%3D%220 0 10 6%22 fill%3D%22none%22%3E%3Cpath d%3D%22M1 1L5 5L9 1%22 stroke%3D%22currentColor%22 stroke-width%3D%221.5%22 stroke-linecap%3D%22round%22 stroke-linejoin%3D%22round%22/%3E%3C/svg%3E');
          background-repeat: no-repeat;
          background-position: calc(100% - 25px) center; /* Adjust the position */
          background-size: 15px; /* Size of the arrow */
          padding-right: 30px; /* Space for the arrow */
        }
      }
    }
  }

  &.bookspring {
    .modal-content {
      .close-button {
        background-color: #5A79DD;
        &:hover {
          background-color: darken(#5A79DD, 10%);
        }
      }
      .charity-info-container {
        .charity-info {
          color: #5A79DD;
        }
      }
      .button-container {
        button {
          border: 3px solid #5A79DD;
          background-color: #5A79DD;
          color: white;
          &:hover {
            background-color: darken(#5A79DD, 10%);
            border-color: darken(#5A79DD, 10%);
          }
          &.decline-hoodie {
            border: 3px solid #5A79DD;
            background-color: transparent;
            color: #5A79DD;
            &:hover {
              background-color: darken(#5A79DD, 10%);
              border-color: darken(#5A79DD, 10%);
              color: white;
            }
          }
        }
      }
    }
  }
  &.big-brothers-big-sisters {
    .modal-content {
      .close-button {
        background-color: #079952;
        &:hover {
          background-color: darken(#079952, 10%);
        }
      }
      .charity-info-container {
        .charity-info {
          color: #079952;
        }
      }
      .button-container {
        button {
          border: 3px solid #079952;
          background-color: #079952;
          color: white;
          &:hover {
            background-color: darken(#079952, 10%);
            border-color: darken(#079952, 10%);
          }
          &.decline-hoodie {
            border: 3px solid #079952;
            background-color: transparent;
            color: #079952;
            &:hover {
              background-color: darken(#079952, 10%);
              border-color: darken(#079952, 10%);
              color: white;
            }
          }
        }
      }
    }
  }
  &.casa {
    .modal-content {
      .close-button {
        background-color: #F55651;
        &:hover {
          background-color: darken(#F55651, 10%);
        }
      }
      .charity-info-container {
        .charity-info {
          color: #F55651;
        }
      }
      .button-container {
        button {
          border: 3px solid #F55651;
          background-color: #F55651;
          color: white;
          &:hover {
            background-color: darken(#F55651, 10%);
            border-color: darken(#F55651, 10%);
          }
          &.decline-hoodie {
            border: 3px solid #F55651;
            background-color: transparent;
            color: #F55651;
            &:hover {
              background-color: darken(#F55651, 10%);
              border-color: darken(#F55651, 10%);
              color: white;
            }
          }
        }
      }
    }
  }
}
