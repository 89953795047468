@import '/src/styles/variables.scss';

.portfolio-block-quote {
  position: relative;
  padding: 70px;
  background: #fff;
  @media (max-width: $breakpoint-mobile) {
    padding: 40px;
  }
  .container {
    display: flex;
    justify-content: flex-start;
    gap: 50px;
    @media (max-width: $breakpoint-mobile) {
      display: block;
    }
    .quote-image {
      width: 250px;
      @media (max-width: $breakpoint-mobile) {
        width: 100%;
        max-width: 200px;
        margin: 0 auto 20px;
      }
      img {
        width: 100%;
      }
    }
    .quote-content {
      position: relative;
      width: auto;
      max-width: 70%;
      padding-left: 50px;
      @media (max-width: $breakpoint-mobile) {
        width: 100%;
        max-width: 100%;
        padding-left: 0;
      }
      &:before {
        content: '';
        background-image: url('../../../../src/assets/quote.svg');
        background-repeat: no-repeat;
        width: 45px;
        height: 35px;
        position: absolute;
        top: 0;
        left: -20px;
        @media (max-width: $breakpoint-mobile) {
          background-size: 35px 25px;
          width: 35px;
          height: 25px;
          left: -50px;
        }
      }
      .quote {
        margin-bottom: 30px;
        font-family: $brandbody;
        font-size: 31px;
        line-height: 1.4;
        @media (max-width: $breakpoint-mobile) {
          font-size: 20px;
        }
      }
      .author {
        p {
          text-transform: uppercase;
          font-weight: 600;
          font-size: 18px;
          color: #288428;
          @media (max-width: $breakpoint-mobile) {
            font-size: 12px;
          }
          em {
            font-style: italic;
          }
        }
      }
    }
  }
}
