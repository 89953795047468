@import '/src/styles/variables.scss';

.primary-header {
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  padding: 6px 0;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 9;
  .content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .logo {
      max-width: 285px;
      width: 285px;
      @media (max-width: $breakpoint-mobile) {
        max-width: 200px;
        width: 200px;
      }
      a {
        display: flex;
        align-items: center;
        img {
          width: 100%;
        }
      }
    }
    .primary-nav {
      @media (max-width: $breakpoint-mobile) {
        position: absolute;
        top: 40px;
        left: 0;
        opacity: 0;
        background: #fff;
        height: 0;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;
        z-index: 8;
        transition: 0.3s ease;
        &.active {
          height: 80vh;
          opacity: 1;
          box-shadow: 0px 10px 6px #00000029;
          .nav-items {
            display: block;
          }
        }
      }
      .nav-items {
        @media (max-width: $breakpoint-mobile) {
          display: none;
        }
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        list-style: none;
        margin: 0;
        .nav-item {
          position: relative;
          @media (max-width: $breakpoint-mobile) {
            padding: 7px 0;
          }
          a {
            font-family: $brandheader;
            font-size: 20px;
            font-weight: 700;
            color: #000;
            text-decoration: none;
            width: 100%;
            padding: 0px 15px;
            transition: 0.3 ease;
            cursor: pointer;
            img {
              margin-left: 10px;
              transition: 0.3 ease;
              &.white {
                filter: brightness(0) invert(1);
              }
            }
          }
          &:hover {
            > a {
              color: #10A63A;
              img {
                filter: invert(67%) sepia(69%) saturate(6133%) hue-rotate(108deg) brightness(92%) contrast(87%);
              }
            }
          }
          .nav-item-children {
            position: absolute;
            background: #EFEFEF;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
            margin-top: 10px;
            opacity: 0;
            height: 0;
            transition: 0.3s ease;
            @media (max-width: $breakpoint-mobile) {
              z-index: 11;
              top: 0;
              left: 100px;
            }
            &.active {
              height: auto;
              opacity: 1;
              .nav-item-child {
                display: block;
              }
            }
            .nav-item-child {
              display: none;
              > a {
                display: block;
                padding: 15px;
                font-size: 17px;
                color: #000;
                transition: 0.3 ease;
              }
              &:hover {
                > a.child-link {
                  color: #10A63A;
                }
              }
            }
          }
        }
      }
    }
    .mobile-menu-toggle {
      display: none;
      @media (max-width: $breakpoint-mobile) {
        display: block;
      }
      button {
        font-size: 28px;
        color: #000;
        text-decoration: none;
        font-weight: 900;
      }
    }
  }
}
