@import '/src/styles/variables.scss';

.work-habits {
  display: block;
  position: relative;
  padding: 100px 0;
  .subsection {
    position: relative;
    .container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: space-between;
      align-items: center;

      .description {
        width: 1000px;
        max-width: 100%;
        margin-bottom: 30px;
        @media (max-width: $breakpoint-tablet) {
          width: 100%;
        }
        h2, p {
          color: #5A79DD;
        }
        h2 {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 20px;
          font-size: 50px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          animation: float 8s ease-in-out infinite;
          animation-direction: alternate-reverse;
          @media (max-width: $breakpoint-tablet) {
            font-size: 45px;
          }
          @media (max-width: $breakpoint-mobile) {
            font-size: 30px;
          }
          img {
            width: 78px;
            height: 130px;
            animation: star-rotate-scale 4.5s ease-in-out infinite;
            @media (max-width: $breakpoint-mobile) {
              width: 20px;
              height: 30px;
            }
          }
        }
        p {
          font-family: "Helvetica Neue";
          font-size: 30px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-left: 90px;
          animation: float 10s ease-in-out infinite;
          animation-direction: alternate-reverse;
          @media (max-width: $breakpoint-mobile) {
            font-size: 22px;
            padding: 0;
          }
        }
      }
      .examples {
        width: 600px;
        max-width: 100%;
        height: 600px;
        position: relative;
        @media (max-width: $breakpoint-mobile) {
          margin-top: 30px;
        }
        .example {
          position: absolute;
          animation: float 3s ease-in-out infinite;
          animation-direction: alternate-reverse;
          img {
            width: 100%;
          }
          &.one {
            animation-duration: 3.5s;
          }
          &.two {
            animation-duration: 4.5s;
          }
          &.three {
            animation-duration: 2.5s;
          }
          &.four {
            animation-duration: 5.5s;
          }
        }
      }
      .sub-description {
        position: relative;
        width: 500px;
        max-width: 100%;
        height: 100px;
        padding: 10px 30px;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: float 3s ease-in-out infinite;
        animation-direction: alternate-reverse;
        @media (max-width: $breakpoint-mobile) {
          height: 70px;
        }
        h3 {
          font-size: 30px;
          text-align: center;
          font-weight: 800;
          line-height: normal;
          @media (max-width: $breakpoint-mobile) {
            font-size: 22px;
          }
        }
      }
    }
    .background-media {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .working-comfortably {
    .container {
      .examples {
        @media (max-width: $breakpoint-tablet) {
          height: 500px;
        }
        @media (max-width: $breakpoint-mobile) {
          height: 350px;
        }
        .example {
          &.one {
            width: 400px;
            bottom: 20px;
            left: -50px;
            @media (max-width: $breakpoint-tablet) {
              width: 320px;
              left: 0;
            }
            @media (max-width: $breakpoint-mobile) {
              width: 250px;
            }
          }
          &.two {
            width: 600px;
            bottom: -130px;
            right: -200px;
            @media (max-width: $breakpoint-tablet) {
              width: 400px;
              right: -60px;
              bottom: -50px;
            }
            @media (max-width: $breakpoint-mobile) {
              width: 300px;
            }
          }
          &.three {
            width: 150px;
            top: 50px;
            left: 375px;
            @media (max-width: $breakpoint-tablet) {
              width: 100px;
              top: 50px;
              left: 325px;
            }
            @media (max-width: $breakpoint-mobile) {
              width: 50px;
              top: 30px;
              left: 275px;
            }
          }
          &.four {
            width: 100px;
            top: 125px;
            left: 575px;
            @media (max-width: $breakpoint-tablet) {
              top: 150px;
              left: 400px;
            }
            @media (max-width: $breakpoint-mobile) {
              width: 50px;
              top: 100px;
              left: 300px;
            }
          }
        }
      }
      .sub-description {
        h3 {
          color: #CA8B30;
        }
      }
    }
    .background-media {
      .leaves {
        position: absolute;
        top: -5%;
        left: 0;
      }
      .star {
        position: absolute;
        &.green {
          &.one {
            top: 80%;
            left: 20%;
          }
          &.two {
            top: 20%;
            left: 80%;
          }
          @media (max-width: $breakpoint-tablet) {
            top: 85%;
            left: 10%;
          }
        }
      }
      .circle-burst {
        position: absolute;
        &.green {
          top: 60%;
          left: 93%;
          @media (max-width: $breakpoint-tablet) {
            top: -15%;
          }
        }
      }
      .cord {
        position: absolute;
        right: 0;
        top: 20%;
        @media (max-width: $breakpoint-mobile) {
          display: none;
        }
      }
    }
  }

  .unplugging-and-recharging {
    .container {
      .examples {
        @media (max-width: $breakpoint-tablet) {
          height: 500px;
        }
        @media (max-width: $breakpoint-mobile) {
          height: 350px;
        }
        .example {
          &.one {
            width: 400px;
            bottom: 20px;
            left: -50px;
            @media (max-width: $breakpoint-tablet) {
              width: 320px;
              left: 0;
            }
            @media (max-width: $breakpoint-mobile) {
              width: 250px;
            }
          }
          &.two {
            width: 600px;
            bottom: -130px;
            right: -200px;
            @media (max-width: $breakpoint-tablet) {
              width: 400px;
              right: -60px;
              bottom: -50px;
            }
            @media (max-width: $breakpoint-mobile) {
              width: 300px;
            }
          }
          &.three {
            width: 150px;
            top: 50px;
            left: 375px;
            @media (max-width: $breakpoint-tablet) {
              width: 100px;
              top: 50px;
              left: 325px;
            }
            @media (max-width: $breakpoint-mobile) {
              width: 50px;
              top: 30px;
              left: 275px;
            }
          }
        }
      }
      .sub-description {
        h3 {
          color: #328248;
        }
      }
    }
    .background-media {
      .flower {
        position: absolute;
        &.yellow {
          top: 20%;
          left: -10%;
          @media (max-width: $breakpoint-mobile) {
            display: none;
          }
        }
      }
      .star {
        position: absolute;
        &.blue {
          top: 80%;
          left: 20%;
          @media (max-width: $breakpoint-tablet) {
            top: 85%;
            left: 10%;
          }
        }
      }
      .circle-burst {
        position: absolute;
        &.yellow {
          top: 0;
          left: 93%;
          @media (max-width: $breakpoint-tablet) {
            top: -15%;
          }
        }
      }
      .cord {
        position: absolute;
        right: 0;
        top: 20%;
        @media (max-width: $breakpoint-mobile) {
          display: none;
        }
      }
    }
  }

}
