@import '/src/styles/variables.scss';


@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.overview-mobile {
  position: relative;
  padding: 50px 0;
  background: #248065;
  overflow: hidden;
  h2 {
    font-family: Tuppence;
    font-size: 28px;
    color: #F1EBDD;
    text-align: center;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 41px;
      background: #DEFFF9;
      filter: blur(50px);
      opacity: 0.5;
      width: 746px;
      height: 100%;
      z-index: 0;
    }
  }
  .scroll-message {
    margin-top: 50px;
    text-align: center;
    p {
      color: #F1EBDD;
      font-size: 40px;
      font-family: 'Caveat', cursive;
    }
    .arrow-indicator {
      animation: bounce 2s infinite;
      background: #F1EBDD;
      padding: 10px 0;
      margin: 10px auto 0;
      border-radius: 22px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
