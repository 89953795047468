@import '/src/styles/variables.scss';

@keyframes rotate-clockwise {
  from {
    transform: rotate(0deg) translate(0, 0) scale(0.8);
  }
  25% {
    transform: rotate(90deg) translate(12.5px, -12.5px);
  }
  50% {
    transform: rotate(180deg) translate(0, -25px) scale(1.2);
  }
  75% {
    transform: rotate(270deg) translate(-12.5px, -12.5px);
  }
  to {
    transform: rotate(360deg) translate(0, 0) scale(0.8);
  }
}

@keyframes rotate-counter-clockwise {
  from {
    transform: rotate(0deg) translate(0, 0) scale(1);
  }
  25% {
    transform: rotate(-90deg) translate(-12.5px, -12.5px);
  }
  50% {
    transform: rotate(-180deg) translate(-25px, 0) scale(0.75);
  }
  75% {
    transform: rotate(-270deg) translate(-12.5px, 12.5px);
  }
  to {
    transform: rotate(-360deg) translate(0, 0) scale(1);
  }
}

@keyframes star-rotate-scale {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.holiday-gift-2024 {
  position: relative;
  // height: 100vh;
  // overflow-y: auto;
  // scrollbar-width: none; /* Firefox */
  // &::-webkit-scrollbar {
  //   display: none; /* Chrome, Safari, Opera */
  // }
  .primary-header, .primary-footer {
    display: none;
  }
  h2, h3 {
    font-family: "new-hero", sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 50px;
    @media (max-width: $breakpoint-tablet) {
      font-size: 30px;
    }
    @media (max-width: $breakpoint-mobile) {
      font-size: 22px;
    }
  }
  h3 {
    font-size: 40px;
    @media (max-width: $breakpoint-tablet) {
      font-size: 30px;
    }
    @media (max-width: $breakpoint-mobile) {
      font-size: 22px;
    }
  }
  p,a, button {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 28px;
    @media (max-width: $breakpoint-tablet) {
      font-size: 22px;
      br {
        display: block;
      }
    }
    @media (max-width: $breakpoint-mobile) {
      font-size: 18px;
      br {
        display: block;
      }
    }
  }
  .holiday-gift-2024-content {
    overflow: hidden;
  }
  .background-texture {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .background-media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    .circle-burst {
      position: absolute;
      display: block;
      width: 200px;
      height: 200px;
      @media (max-width: $breakpoint-tablet) {
        width: 120px;
        height: 120px;
      }
      @media (max-width: $breakpoint-mobile) {
        width: 75px;
        height: 75px;
      }
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        &.circle {
          z-index: 1;
          animation: rotate-counter-clockwise 450s linear infinite, move-clockwise 25s linear infinite;
        }
        &.burst {
          z-index: 2;
          animation: rotate-clockwise 50s linear infinite, move-counter-clockwise 20s linear infinite;
          // left: 20%;
          // top: 20%;
        }
      }
    }
    .star {
      position: absolute;
      width: 78px;
      height: 130px;
      animation: star-rotate-scale 3s ease-in-out infinite;
      @media (max-width: $breakpoint-tablet) {
        width: 50px;
        height: 80px;
      }
      @media (max-width: $breakpoint-mobile) {
        width: 20px;
        height: 30px;
      }
    }
    .flower {
      width: 450px;
      height: 450px;
      animation: rotate-counter-clockwise 150s linear infinite, move-clockwise 25s linear infinite;
      @media (max-width: $breakpoint-tablet) {
        width: 250px;
        height: 250px;
      }
      // @media (max-width: $breakpoint-mobile) {
      //   width: 150px;
      //   height: 150px;
      // }
    }
  }
}
