@import '/src/styles/variables.scss';

.portfolio-mobile-comps {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    .comp {
      width: 33%;
      max-width: 300px;
    }
  }
}
