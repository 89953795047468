@import '/src/styles/variables.scss';

.clients-experience {
  padding: 50px 0;
  h2 {
    font-family: $brandheader;
    font-weight: bold;
    font-size: 36px;
    color: #288428;
    margin-bottom: 50px;
    @media (max-width: $breakpoint-mobile) {
      text-align: center;
      margin-bottom: 75px;
    }
  }
  .clients-wrapper {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 64px;
    margin-bottom: 45px;
    .client {
      display: flex;
      justify-items: center;
      justify-content: center;
      align-items: center;
      max-width: 200px;
      max-height: 90px;
      height: 90px;
      @media (max-width: $breakpoint-mobile) {
        width: 35%;
        max-width: 50%;
        margin-bottom: 20px;
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
