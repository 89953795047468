@import '/src/styles/variables.scss';

.new-skills {
  display: block;
  position: relative;
  padding: 100px 0;
  @media (max-width: $breakpoint-mobile) {
    padding: 50px 0;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    .description {
      width: 100%;
      max-width: 100%;
      margin-bottom: 30px;
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        text-align: center;
      }
      h2, p {
        color: #5A79DD;
      }
      h2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        // animation: float 8s ease-in-out infinite;
        // animation-direction: alternate-reverse;
        @media (max-width: $breakpoint-tablet) {
          align-items: flex-start;
          justify-content: center;
          margin-bottom: 10px;
        }
        img {
          width: 78px;
          height: 130px;
          animation: star-rotate-scale 4.5s ease-in-out infinite;
          @media (max-width: $breakpoint-tablet) {
            width: 20px;
            height: 30px;
          }
        }
      }
      p {
        padding-left: 100px;
        color: #414141;
        // animation: float 10s ease-in-out infinite;
        // animation-direction: alternate-reverse;
        @media (max-width: $breakpoint-tablet) {
          padding-left: 0;
        }
      }
    }
    .examples {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 50px;
      align-items: flex-start;
      justify-content: flex-end;
      margin-top: 50px;
      @media (max-width: $breakpoint-tablet) {
        justify-content: center;
        gap: 30px;
      }
      @media (max-width: $breakpoint-mobile) {
        margin: 30px auto 0;
      }

      // First row - 3 items
      // .example:nth-child(-n+3) {
      //   margin-left: calc(300px / 2); // Offset by half an item width
      // }

      .example {
        // animation: float 3s ease-in-out infinite;
        // animation-direction: alternate-reverse;
        text-align: center;
        width: 260px;
        @media (max-width: 1265px) {
          width: 200px;
        }
        @media (max-width: $breakpoint-tablet) {
          width: 175px;
        }
        @media (max-width: $breakpoint-mobile) {
          width: 175px;
          max-width: calc(50% - 15px);
        }
        .img-container {
          width: 260px;
          height: auto;
          margin-bottom: 20px;
          @media (max-width: 1265px) {
            width: 200px;
          }
          @media (max-width: $breakpoint-tablet) {
            width: 175px;
          }
          @media (max-width: $breakpoint-mobile) {
            width: 100%;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        p {
          font-size: 18px;
          color: #5A79DD;
          font-weight: 700;
          @media (max-width: $breakpoint-tablet) {
            font-size: 16px;
          }
          @media (max-width: $breakpoint-mobile) {
            font-size: 14px;
          }
        }
        &.annie {
          p{
            color: #CA8B30;
          }
        }
        &.bud {
          p{
            color: #5A79DD;
          }
        }
        &.lauren {
          p{
            color: #F55651;
          }
        }
        &.bryan {
          p{
            color: #5A79DD;
          }
        }
        &.jill {
          p{
            color: #F55651;
          }
        }
        &.mason {
          p{
            color: #079952;
          }
        }
        &.mark {
          p{
            color: #CA8B30;
          }
        }
      }
    }
  }
  .background-media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .star {
      position: absolute;
      &.yellow {
        top: 80%;
        left: 95%;
        @media (max-width: $breakpoint-mobile) {
          display: none;
        }
      }
    }
    .flower {
      position: absolute;
      &.red {
        top: 55%;
        left: -8%;
        @media (max-width: 1265px) {
          top: 55%;
          left: -8%;
          width: 300px;
          height: 300px;
        }
        @media (max-width: $breakpoint-tablet) {
          top: 65%;
          left: -20%;
        }
        @media (max-width: $breakpoint-mobile) {
          width: 200px;
          height: 200px;
          left: 80%;
          top: 70%;
        }
      }
    }
  }
  .lightbox {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);

    .close-button {
      position: fixed;
      top: 20px;
      right: 20px;
      background: none;
      border: none;
      color: white;
      font-size: 24px;
      cursor: pointer;
      z-index: 1001;
    }

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    img {
      transform: scale(0.95);
      transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &.visible img {
      transform: scale(1);
    }
  }
}
