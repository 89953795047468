@import '/src/styles/variables.scss';

.accordion-carousel {
  position: relative;
  .subheader {
    .desktop {
      display:inline;
    }
    .mobile{
      display: none;
    }
    @media (max-width: $breakpoint-mobile) {
      .desktop {
        display:none;
      }
      .mobile{
        display: inline;
      }
    }
  }
  .desktop {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
    margin: 100px 0;
    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
    .accordion{
      width: 50%;
    }
    .image {
      width: 45%;
    }
    .accordion {
      .accordion-container {
        // max-height: 400px;
        margin-top: 20px;
        flex: 1;
        .accordion-item {
          border-bottom: 1px solid #ddd;
          overflow: hidden;
          // transition: all .15s ease-in-out; // Adjusted for smoother transition

          &:last-child {
            border-bottom: none;
          }

          h4 {
            position: relative;
            cursor: pointer;
            margin: 0;
            padding: 15px;
            padding-left: 30px;
            color: $brandgreen;
            font-size: 18px;
            transition: background .5s ease-in-out;
            &:before {
              content: url("./assets/accordion-expand.svg");
              position: absolute;
              left:0;
              top: 50%;
              transform: translateY(-50%);
              height: 25px;
              width: 25px;
            }
          }
          p {
            margin: 0;
            padding: 0;
            background: transparent;
            height: 0;
            opacity: 0;
            overflow: hidden;
            transition: height .5s ease, opacity .5s ease;
          }

          &.active {
            // transition: all 1s ease-in-out;
            h4 {
              &:before {
                content: url("./assets/accordion-close.svg");
              }
            }
            p {
              height: 145px;
              opacity: 1;
              transition: height .5s ease, opacity .5s ease;
            }
          }
        }
      }
    }
    .image {
      position: relative;
      height: 450px;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        max-width: 100%;
        max-height: 100%;
        transition: opacity 0.5s ease-in-out;
        opacity: 0;
        &.active {
          opacity: 1;
          transition: opacity 0.5s ease-in-out;
        }
      }
    }
  }
  .mobile {
    display: none;
    @media (max-width: $breakpoint-mobile) {
      display: block;
      .accordion-item {
        width: 100%;
        .image {
          width: 300px;
          margin: 0 auto;
          margin-top: 30px;
          margin-bottom: 40px;
        }
        h4, p {
          text-align: left;
        }
        h4 {
          color: $brandgreen;
          font-size: 18px;
          margin-bottom: 20px;
        }
        p {

        }
      }
    }
    .carousel {
      .slider-wrapper {
        position: relative;
        padding-bottom: 50px;
        // &:after {
        //   content: '';
        //   width: 200px;
        //   height: 1px;
        //   position: absolute;
        //   bottom: 0;
        //   left: 50%;
        //   transform: translateX(-50%);
        //   background: #000;
        // }
      }
    }
    .control-dots {
      // top: 340px;
      // bottom: auto;
      .dot {
        box-shadow: none;
        background: $brandgreen;
        width: 14px;
        height: 14px;
        margin: 13px;
      }
    }
  }
}
