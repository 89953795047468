@import '/src/styles/variables.scss';

.experiences {
  display: block;
  position: relative;
  padding: 100px 0;
  @media (max-width: $breakpoint-mobile) {
    padding: 50px 0;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    .description {
      width: 900px;
      max-width: 100%;
      margin-bottom: 30px;
      text-align: center;
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        text-align: center;
      }
      h2, p {
        color: #C59105;
      }
      h2 {
        margin-bottom: 30px;
        // animation: float 8s ease-in-out infinite;
        // animation-direction: alternate-reverse;
        img {
          width: 78px;
          height: 130px;
          animation: star-rotate-scale 4.5s ease-in-out infinite;
          @media (max-width: $breakpoint-mobile) {
            width: 20px;
            height: 30px;
          }
        }
      }
      p {
        color: #414141;
      }
    }
    .examples {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 30px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      align-content: center;
      @media (max-width: $breakpoint-mobile) {
        gap: 20px;
      }
      // @media (max-width: $breakpoint-mobile) {
      //   // justify-content: flex-start;
      //   gap: 10px;
      // }
      .example {
        // animation: float 3s ease-in-out infinite;
        // animation-direction: alternate-reverse;
        text-align: center;
        // width: 275px;
        // height: 275px;
        width: 300px;
        max-width: 30%;
        // cursor: pointer;
        @media (max-width: $breakpoint-tablet) {
          max-width: 45%;
        }
        // @media (max-width: $breakpoint-mobile) {
        //   width: 200px;
        //   max-width: 45%;
        //   height: 150px;
        // }
        img {
          width: 100%;
        }
        // &.art-barn {
        //   animation-duration: 3s;
        // }
        // &.ice-cream-making {
        //   animation-duration: 5s;
        // }
        // &.escape-room {
        //   animation-duration: 3.5s;
        // }
        // &.paint-by-number {
        //   animation-duration: 4s;
        // }
        // &.vision-boards {
        //   animation-duration: 4.5s;
        // }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .background-media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .flower {
      position: absolute;
      &.yellow {
        top: 0%;
        left: -200px;
        @media (max-width: 1265px) {
          left: -300px;
        }
        @media (max-width: $breakpoint-tablet) {
          display: none;
        }
        @media (max-width: $breakpoint-mobile) {
          display: none;
        }
      }
    }
    .circle-burst {
      position: absolute;
      &.yellow {
        top: 75%;
        left: 90%;
        @media (max-width: $breakpoint-mobile) {
          top: 90%;
          left: 80%;
        }
        img {
          &.burst {
            left: -20%;
            top: 20%;
          }
        }
      }
    }
    .star {
      position: absolute;
      @media (max-width: $breakpoint-mobile) {
        display: none;
      }
      &.blue {
        top: 75%;
        left: 15%;
        animation-duration: 2.5s;
        @media (max-width: $breakpoint-tablet) {
          top: 65%;
          left: 12%;
        }
      }
      &.red {
        top: 5%;
        left: 85%;
        animation-duration: 5s;
        @media (max-width: $breakpoint-tablet) {
          top: 0%;
        }
      }
    }
  }
  .lightbox {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);

    .close-button {
      position: fixed;
      top: 20px;
      right: 20px;
      background: none;
      border: none;
      color: white;
      font-size: 24px;
      cursor: pointer;
      z-index: 1001;
    }

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    img {
      transform: scale(0.95);
      transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &.visible img {
      transform: scale(1);
    }
  }
}
