@import '/src/styles/variables.scss';

.copy-and-image-block {
  position: relative;
  .background-media {
    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
    &.copyimage {
      flex-direction: row;
    }
    &.imagecopy {
      flex-direction: row-reverse;
    }
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .empty, .image {
      width: 50%;
      height: 100%;
    }
    .image {
      background-size: cover;
      background-position: center;
      height: 100%;
    }
  }
  .container {
    display: flex;;
    &.copyimage {
      flex-direction: row;
      .copy {
        padding: 50px 50px 50px 0;
        @media (max-width: $breakpoint-mobile) {
          padding: 50px 0;
        }
      }
    }
    &.imagecopy {
      flex-direction: row-reverse;
      .copy {
        padding: 50px 0 50px 50px;
        @media (max-width: $breakpoint-mobile) {
          padding: 50px 0;
        }
      }
    }
    .copy {
      width: 50%;
      padding: 50px 50px 50px 0;
      color: $brandgreen;
      @media (max-width: $breakpoint-mobile) {
        width: 100%;
      }
      h3 {
        font-size: 35px;
      }
      p {
        font-size: 17px;
      }
    }
  }
}
