@import '/src/styles/variables.scss';

.portfolio-motion-graphic-feature {
  position: relative;
  padding: 50px 0;
  &.extra-spacing {
    margin: 50px 0;
  }
  video {
    width: 100%;
  }
}
