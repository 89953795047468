@import '/src/styles/variables.scss';


.leadership {
  position: relative;
  background:  $brandmediumbg;
  color: #fff;
  .container {
    display:  flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column-reverse;
      height: auto;
    }
    > div {
      width: 50%;
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
      }
      &.about {
        padding: 75px 50px 75px 0;
        h2 {
          position: relative;
          margin-bottom: 50px;
          &::before {
            content: '';
            position: absolute;
            top: -10px;
            background-color: white;
            height: 8px;
            width: 400px;
            max-width: 100%;
            @media (max-width: $breakpoint-mobile) {
              height: 4px;
              max-width: 50%;
            }
          }
        }
        p {
          font-family: $brandbody;
          font-size: 18px;
        }
      }
      &.image {
        height: 100%;
        @media (max-width: $breakpoint-tablet) {
          height: 300px;
        }
        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }
}
