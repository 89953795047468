@import '/src/styles/variables.scss';

.portfolio-branding-banner {
  position: relative;
  background: #fff;
  padding: 50px 0;
  &.extra-space {
    margin: 50px 0;
  }
  &.background {
    height: 400px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &.foreground {
    img {
      width: 100%;
    }
  }
}
