@import '/src/styles/variables.scss';

.portfolio-item-hero {
  position: relative;
  height: 100vh;
  min-height: 800px;
  background: #171717;
  @media (max-width: $breakpoint-tablet) {
    height: auto;
    min-height: 100vh;
  }
  .featured-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 45%;
    height: 90%;
    @media (max-width: $breakpoint-tablet) {
      width: 100%;
      height: 50%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .container {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    height: 100%;
    @media (max-width: $breakpoint-tablet) {
      justify-content: center;
      align-items: center;
    }
    .hero-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      z-index: 1;
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        background: #171717;
        justify-content: flex-start;
        margin-top: 20vh;
        margin-bottom: 30px;
        padding: 30px;
        border-radius: 5px;
      }
      .services-provided {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 30px;
        flex-wrap: wrap;
        .service {
          margin-right: 5px;
          margin-bottom: 10px;
          background: #fff;
          padding: 5px 20px;
          border-radius: 20px;
          span {
            font-size: 14px;
            font-family: $brandheader;
            font-weight: 600;
            color: #288428;
            text-transform: uppercase;
            @media (max-width: $breakpoint-tablet) {
              font-size: 12px;
            }
          }
        }
      }
      h1, p {
        color: #fff;
      }
      h1 {
        border-top: 2px solid #fff;
        margin-bottom: 50px;
      }
      .project-background {
        .label {
          display: block;
          text-transform: uppercase;
          color: #4BAD43;
          font-size: 18px;
          margin-bottom: 20px;
          font-weight: 600;
        }
        p {
          font-size: 17px;
        }
      }
    }
  }
}
