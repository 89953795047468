@import '/src/styles/variables.scss';

.culture-hero {
  max-width: 100vw;
  background-size: cover;
  background-image: none;
  display: block;
  position: relative;
  min-height: 728px;
  background: #1F1F1F;
  overflow: hidden;
  @media (max-width: $breakpoint-mobile) {
    min-height: auto;
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    object-fit: cover;
    object-position: center center;
    z-index: 0;
  }
  .content {
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 134px 0 80px 0;
    box-sizing: border-box;
    color: #fff;
    z-index: 1;
    position: relative;
    margin: 0;
    max-width: 810px;
    .white-bar {
      height: 8px;
      background-color: white;
      width: 400px;
      max-width: 100%;
    }
    h1 {
      color: #fff;
      font-size: 90px;
      margin-bottom: 20px;
      font-weight: bold;
      margin-top: 20px;
      @media (max-width: $breakpoint-mobile) {
        font-size: 40px;
      }
    }
    p {
      font-family: $brandbody;
      font-size: 20px;
      line-height: 30px;
      max-width: 72%;
      margin: 0;
      font-weight: 700;
      color: #fff;
      @media (max-width: $breakpoint-mobile) {
        font-size: 18px;
        max-width: 100%;
      }
    }
  }
}
