@import '/src/styles/variables.scss';

.the-safe-alliance {
  position: relative;
  padding: 50px 0;
  background: #033837;
  .content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid #EEB051;
    .about {
      width: 55%;
      margin-bottom: 50px;
      color: #fff;
      h2 {
        color: #fff;
        margin-bottom: 30px;
        font-size: 40px;
      }
      span {
        font-family: $manofa;
        font-weight: 600;
      }
      p {
        text-align: left;
        margin-bottom: 20px;
        color: #fff;
      }
    }
    .statistics {
      width: 45%;
      display: flex;
      flex-direction: column;
      .stat {
        max-width: 300px;
        margin: 0 auto 30px;
        text-align: center;
        h3{
          margin-bottom: 10px;
          font-size: 40px;
          font-weight: 600;
          color: #57C6B9;
        }
        p {
          font-weight: 600;
          color: #fff;
        }
      }
    }
  }
  .logos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    img {
      margin-bottom: 50px;
    }
    p {
      margin-bottom: 50px;
      color: #fff;
      font-weight: 600;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    .content {
      display: block;
      text-align: center;
      .about, .statistics {
        width: 100%;
      }
    }
    .logos {
      img {
        max-width: 300px;
      }
    }

  }

}
