@import '/src/styles/variables.scss';

.full-image {
  position: relative;
  padding: 50px 0;
  &.contained {
    .container {
      img {
        width: 100%;
      }
    }
  }
  &.full, &.fixed {
    img {
      width: 100%;
    }
  }
  &.fixed {
    height: 100vh;
    padding: 0;
    background-color: #000000;
    padding-top: 0px;
    padding-bottom: 0px;
    background: none;
    background-attachment: fixed;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: $breakpoint-mobile) {
      background-attachment: initial;
      height: 600px;
    }
  }
}
