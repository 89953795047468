@import '/src/styles/variables.scss';

@keyframes modalFade {
  0%   {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.donation {
  position: relative;
  padding: 200px 0 50px;
  .donation-about {
    width: 600px;
    max-width: 100%;
    margin: 0 auto 100px;
    text-align: center;
    img {
      margin: 0 auto 30px;
      max-width: 100%;
    }
    p {
      color: #033837;
      margin-bottom: 20px;
      strong {
        font-weight: 600;
      }
    }
  }


  .donations-mobile.select-donation {
    display: none
  }

  @media (max-width: $breakpoint-mobile) {
    .donation-about {
      img {
        max-width: 300px;
      }
    }

    .donations-desktop.select-donation {
      display: none;
    }
    .donations-mobile.select-donation {
      display: block;
    }


  }

}


.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 800px;
  max-width: 90vw;
  min-height: 500px;
  background-color: #fff;
  border-radius: 25px;
  padding: 30px;
  animation-name: modalFade;
  animation-duration: 0.4s;
  .close {
    position: absolute;
    right: 30px;
    top: 30px;
  }
  .modal-content {
    text-align: center;
    .item-visual {
      width: 100px;
      height: 100px;
      margin: 0 auto 30px;
      border-radius: 50%;
      position: relative;
      background: green;
      .main {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-out;
        transform-origin: center;
      }
      .main {
        width: 100%;
        max-width: 85%;
        max-height: 65%;
        z-index: 2;
        transition: all 0.2s ease-out;
      }
    }
    h2 {
      font-size: 28px;
      margin-bottom: 20px;
    }
    p {
      color: #033837;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
    }
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      label {
        width: 100%;
        input {
          width: 80%;
          border-radius: 25px;
          border: 1px solid #83837C;
          padding: 10px 20px;
        }
      }
      .submit-donation {
        margin-top: 30px;
        border-radius: 25px;
        border: none;
        background: #DE5024;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        padding: 15px 30px;
        box-shadow:  2px 2px 10px -2px #707070;
        cursor: pointer;
      }
    }
  }
  .modal-thank-you {
    width: 90%;
    text-align: center;
    img {
      display: block;
      width: 100%;
      max-width: 350px;
      margin: 0 auto;
    }
    p {
      margin-top: 30px;
      strong {
        font-weight: 500;
      }
    }
  }


  @media (max-width: $breakpoint-mobile) {
    .modal-content {
      h2 {
        font-size: 24px;
      }
    }
  }
}
