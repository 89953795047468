@import '/src/styles/variables.scss';

.client-offerings {
  padding: 50px 0;
  .tabs {
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-top: 50px;
    .tab {
      transition: 0.2s ease-in-out;
      padding: 5px 10px;
      border-radius: 12px;
      background-color: transparent;
      p {
        font-weight: 400;
      }
      &.active {
        background-color: gray;
      }
    }
  }
  .client-offerings-carousel {
    // max-width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    gap: 50px;
    overflow-x: visible;
    scrollbar-width: none; // Firefox
    -ms-overflow-style: none; // IE/Edge
    &::-webkit-scrollbar {
      display: none; // Chrome/Safari/Opera
    }
    .client-offering-item {
      width: 669px;
      height: 308px;
      flex-shrink: 0;
      border-radius: 21px;
      background: #F6F6F6;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding:30px;
      transition: 0.2s ease-in-out;
      &.active {
        transform: scale(1.1);
      }
      h3 {
        margin-bottom: 30px;
      }
    }
  }
}
