@import '/src/styles/variables.scss';

.job-title {
  position: relative;
  padding: 150px 0 50px;
  background: $brandgreen;
  h1 {
    color: #fff;
    position: relative;
    display: inline-block;
    &:after {
      content: '';
      position: absolute;
      top: -15px;
      left: 0;
      height: 8px;
      width: 100%;
      background: #fff;
    }
  }
  p {
    color: #fff;
  }
}
