@import '/src/styles/variables.scss';

.page-home {
  background: #000;
  .primary-header {
    background: transparent linear-gradient(180deg, #000000 0%, #00000000 100%) 0% 0% no-repeat padding-box;
    box-shadow: none;
    .content-container {
      .primary-nav {
        .nav-items {
          .nav-item {
            > a {
              color: #fff;
            }
            &:hover {
              > a {
                color: $brandgreenaccessible;
              }
            }
          }
        }
      }
    }
  }
}
