@import '/src/styles/variables.scss';

.intro-mobile {
  position: relative;
  padding: 75px 0 50px;
  min-height: 100vh;
  height: auto;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #12A185;
  overflow: hidden;
  &:before, &:after {
    content: '';
    position: absolute;
    height: 1000px;
    transform: rotate(40deg);
  }
  &:before {
    top: -250px;
    left:  -75px;
    width: 100px;
    background: #1D8B6B;
    z-index: 1;
  }
  &:after {
    bottom: -250px;
    right: -75px;
    width: 100px;
    background: #248065;
    z-index: 2;
  }
  .logo {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;;
    z-index: 9;
  }
  .brand {
    position: absolute;
    bottom: 0;
    right: 30px;
    width: 50px;
    z-index: 9;
  }
  .container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    z-index: 3;
    padding: 0 20px;
    .gift-tag {
      position: relative;
      background-color: #F1EBDD;
      padding: 20px;
      box-shadow: 0px 4px 4px 8px rgba(0, 0, 0, 0.10);
      .border {
        position: relative;
        padding: 20px;
        border: 3px dashed #FE8874;
        text-align: left;
        .title {
          position: relative;
          h1 {
            position: relative;
            font-family: Tuppence;
            color: #007048;
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 20px;
            z-index: 1;
          }
        }
        .intro-copy {
          p {
            font-family: "space-grotesk-variable";
            font-size: 14px;
            strong {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
