@import '/src/styles/variables.scss';

.work-samples {
  display: block;
  margin: 100px 0;
  @media (max-width: $breakpoint-mobile) {
    margin: 50px 0;
  }
  .row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
    }
    &.count-1 {
      .project {
        width: 100%;
      }
    }
    &.count-2 {
      .project {
        width: 50%;
      }
      &.offset {
        &.right {
          .project {
            &:first-of-type {
              width: 40%;
            }
            &:last-of-type {
              width: 60%;
            }
          }
        }
        &.left {
          .project {
            &:first-of-type {
              width: 60%;
            }
            &:last-of-type {
              width: 40%;
            }
          }
        }
      }
    }
    &.count-3 {
      .project {
        width: 33%;
      }
    }
    .project {
      position: relative;
      height: 435px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @media (max-width: $breakpoint-mobile) {
        min-width: 100%;
      }
      &:hover {
        .project-information {
          background-color: rgba(0, 0, 0, 0);
        }
        .arrow {
          bottom: 80%;
          right: 5%;
          -webkit-transform: translateY(-100%);
          -ms-transform: translateY(-100%);
          transform: translateY(-100%);
          img {
            transform: scale(1.9);
            transition: -webkit-transform .9s ease-in-out;
            transition: transform .9s ease-in-out;
          }
        }
        .overlay {
          opacity: 1;
        }
      }
      .project-information {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #000000d4;
        height: 120px;
        width: 100%;
        position: absolute;
        bottom: 0;
        padding: 0 30px;
        z-index: 1;
        h4, p {
          color: #fff;
          text-rendering: optimizeLegibility;
          letter-spacing: .5px;
          max-width: 80%;
        }
        h4 {
          font-family: $brandheader;
          font-size: 25px;
          font-weight: bold;
          @media (max-width: $breakpoint-mobile) {
            font-size: 20px;
          }
        }
        p {
          font-family: $brandbody;
          font-size: 15px;
        }
      }
      .arrow {
        bottom: 15%;
        right: 20px;
        transition: all 0.5s ease;
        will-change: bottom;
        position: absolute;
        z-index: 2;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(16, 166, 58, 0.8);
        opacity: 0;
        transition: 0.3s ease-out;
      }
    }
  }
}
