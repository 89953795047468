@import '/src/styles/variables.scss';

.contact-form {
  position: relative;
  padding: 50px 0;
  background: $branddarkbg;
  .container {
    position: relative;
    .form-container {
      position: absolute;
      z-index: 1;
      background: rgba(51, 153, 51, 0.9);
      width: 500px;
      max-width: 50%;
      height: 100%;
      @media (max-width: $breakpoint-tablet) {
        position: relative;
        width: 100%;
        max-width: 100%;
      }
      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 30px 45px;
        @media (max-width: $breakpoint-mobile) {
          padding: 30px 15px;
        }
        > div {
          margin-bottom: 40px;
        }
        .form-input {
          display: flex;
          flex-direction: column;
          width: 100%;
          label {
            display: block;
            margin: 0 0 10px;
            font-family: 'Mercury', serif;
            font-size: 18px;
            color: #fff;
          }
          input, textarea, select {
            padding: 10px 10px;
            width: 90%;
            border: none;
            font-family: inherit;
            font-size: 14px;
          }
          select {
            background: #000;
            color: #fff;
            font-weight: bold;
            font-size: 20px;
          }
        }
        iframe {
          max-width: 100%;
        }
        .submit {
          outline: none;
          border: none;
          width: auto;
          background-color: black;
          margin-bottom: 10px;
          padding: 10px 30px;
          color: white;
          display: inline-block;
          font-size: 18px;
          font-weight: bold;
          cursor: pointer;
          transition: 0.2s ease;
          &:hover {
            background: lighten(#000, 5%);
          }
          &:disabled {
            background-color: #ccc;
            cursor: auto;
          }
        }
        p {
          margin-top: 20px;
          color: #fff;
          font-size: 16px;
          font-family: 'Mercury', serif;
        }
      }
    }
    .submission-message {
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        color: #fff;
        text-align: center;
      }
    }
  }
}
