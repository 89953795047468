@import '/src/styles/variables.scss';

.multi-image-display {
  position: relative;
  display: flex;
  justify-content: center;
  height: 500px;
  @media (max-width: $breakpoint-mobile) {
    height: auto;
    flex-direction: column;
  }
  &.extra-space-top {
    margin-top: 50px;
  }
  &.extra-space-bottom {
    margin-top: 50px;
  }
  .image-container {
    @media (max-width: $breakpoint-mobile) {
     height: 300px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &.count-2 {
    &.center {
      .image-container {
        width: 50%;
        @media (max-width: $breakpoint-mobile) {
          width: 100%;
        }
      }
    }
    &.left {
      .image-container {
        &:first-of-type {
          width: 33%;
          @media (max-width: $breakpoint-mobile) {
            width: 100%;
          }
        }
        &:last-of-type {
          width: 66%;
          @media (max-width: $breakpoint-mobile) {
            width: 100%;
          }
        }
      }
    }
    &.right {
      .image-container {
        &:first-of-type {
          width: 66%;
          @media (max-width: $breakpoint-mobile) {
            width: 100%;
          }
        }
        &:last-of-type {
          width: 33%;
          @media (max-width: $breakpoint-mobile) {
            width: 100%;
          }
        }
      }
    }
  }
  &.count-3 {
    &.center {
      height: 300px;
      .image-container {
        width: 33%;
        @media (max-width: $breakpoint-mobile) {
          width: 100%;
        }
      }
    }
    &.left, &.right {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 800px;
      @media (max-width: $breakpoint-mobile) {
        display: block;
      }
      .single, .stacked {
        width: 50%;
        height: 100%;
        @media (max-width: $breakpoint-mobile) {
          width: 100%;
        }
      }
      .single {
        @media (max-width: $breakpoint-mobile) {
          height: 33%;
        }
      }
      .stacked {
        display: flex;
        flex-direction: column;
        @media (max-width: $breakpoint-mobile) {
          height: 66%;
        }
        img {
          height: 50%;
        }
      }
    }
    &.left {
      flex-direction: row;
    }
    &.right {
      flex-direction: row-reverse;
    }
   }
}
