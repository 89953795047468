@import '/src/styles/variables.scss';

.capabilities {
  min-height: 100vh;
  background: $branddarkbg;
  .embed-container {
    padding: 50px 20px 50px;
    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
    iframe {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      width: 100%;
      min-height: 900px;
      @media (max-width: $breakpoint-mobile) {
        min-height: 50vh;
      }
      &:first-of-type {
        margin-bottom: 50px;
      }
    }


    .restart {
      display: block;
      margin: 0 auto;
      background: #fff;
      padding: 5px 20px;
      border-radius: 20px;
      font-size: 14px;
      font-family: $brandheader;
      font-weight: 600;
      color: #288428;
      text-transform: uppercase;
      @media (max-width: $breakpoint-tablet) {
        font-size: 12px;
      }
    }
  }

  .mobile-display {
    display: none;
    @media (max-width: $breakpoint-mobile) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 250px 0 200px;
      img {
        margin-bottom: 30px;
      }
      p {
        color: #fff;
        font-size: 24px;
        text-align: center;
      }
    }
  }
}
