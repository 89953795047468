@import '/src/styles/variables.scss';

.life-changing-experiences {
  display: block;
  position: relative;
  padding: 100px 0 200px;
  @media (max-width: $breakpoint-mobile) {
    padding: 50px 0 50px;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      gap: 0;
    }
    .description {
      width: 100%;
      max-width: 800px;
      @media (max-width: $breakpoint-tablet) {
        margin: 0 auto;
        text-align: center;
      }
      h2, p {
        color: #F85B44;
      }
      h2 {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        gap: 20px;
        // animation: float 8s ease-in-out infinite;
        // animation-direction: alternate-reverse;
        @media (max-width: $breakpoint-tablet) {
          align-items: flex-start;
          justify-content: center;
        }
        img {
          width: 78px;
          height: 130px;
          animation: star-rotate-scale 4.5s ease-in-out infinite;
          @media (max-width: $breakpoint-tablet) {
            width: 20px;
            height: 30px;
          }
        }
      }
    }
    .featured-media {
      position: relative;
      max-width: 100%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items:flex-start;
      gap: 20px;
      img {
        width: 400px;
        max-width: 30%;
        // animation: float 10s ease-in-out infinite;
        // animation-direction: alternate-reverse;
        // cursor: pointer;
        @media (max-width: $breakpoint-mobile) {
          position: relative;
          width: 250px;
          max-width: 45%;
        }
        // &.longhorn-100 {
        //   animation-duration: 2s;
        // }
        // &.mason-parent{
        //   animation-duration: 3s;
        // }
        // &.hanna-international {
        //   animation-duration: 2.5s;
        // }
        // &.jill-improv {
        //   animation-duration: 2.1s;
        // }
        // &.mark-anniversary {
        //   animation-duration: 3.5s;
        // }
        // &.nguyen-market {
        //   animation-duration: 4s;
        // }
        // &.annie-stingrays {
        //   animation-duration: 2.7s;
        // }
        // &.mark-milam {
        //   animation-duration: 2.3s;
        // }
        // &.cara-paribas-open {
        //   animation-duration: 3.2s;
        // }
      }
    }
  }
  .background-media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .circle-burst {
      position: absolute;
      left: 75%;
      top: 0;
      @media (max-width: $breakpoint-tablet) {
        left: 90%;
      }
      @media (max-width: $breakpoint-mobile) {
        top: -3%;
      }
      img {
        &.burst {
          top: -30%;
          left: 30%;
        }
      }
    }
    .star {
      position: absolute;
      &.blue {
        top: 35%;
        left: 10%;
        animation-duration: 5s;
      }
      &.green {
        top: 33%;
        left: 50%;
        animation-duration: 4.5s;
      }
      &.red {
        top: 20%;
        left: 90%;
        animation-duration: 3.5s;
      }
    }
  }

  .lightbox {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    // cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);

    .close-button {
      position: fixed;
      top: 20px;
      right: 20px;
      background: none;
      border: none;
      color: white;
      font-size: 24px;
      cursor: pointer;
      z-index: 1001;
    }

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    img {
      transform: scale(0.95);
      transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &.visible img {
      transform: scale(1);
    }
  }
}
